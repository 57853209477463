// assets
import { FormattedMessage } from "react-intl";
import { CATEGORIES } from "constants/routes";
import { IconCategory } from "@tabler/icons";
import { SUPER_ADMIN_ROLE, DOCTOR_ROLE, LEGAL_ROLE } from "constants";

// constant
const icons = { IconCategory };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const categories = {
  id: "categories",
  title: <FormattedMessage id="sidebar.categories.title" />,
  type: "group",
  roles: [SUPER_ADMIN_ROLE, LEGAL_ROLE],
  children: [
    {
      id: "categories-index",
      title: <FormattedMessage id="sidebar.categories.manage.title" />,
      type: "item",
      url: CATEGORIES,
      icon: icons.IconCategory,
      breadcrumbs: false,
    },
  ],
};

export default categories;
