import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthRoute from "middlewares/authRoute";
import HasRoles from "middlewares/hasRoles";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("views/dashboard")));
const EditProfile = Loadable(lazy(() => import("views/profile/forms/edit")));
const About = Loadable(lazy(() => import("views/about")));
import { ABOUT, DASHBOARD, EDIT_PROFILE } from "constants/routes";
import {
  SUPER_ADMIN_ROLE,
  DOCTOR_ROLE,
  ADVISER_ROLE,
  LEGAL_ROLE,
} from "constants";
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: DASHBOARD,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: DASHBOARD,
      element: <DashboardDefault />,
    },

    {
      path: ABOUT,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            SUPER_ADMIN_ROLE,
            ADVISER_ROLE,
            LEGAL_ROLE,
          ]}
        >
          <About />
        </HasRoles>
      ),
    },
    {
      path: EDIT_PROFILE,
      element: <EditProfile />,
    },
  ],
};

export default MainRoutes;
