const CategoryState = {
  categories: [],
  createCategoryDialogIsOpen: false,
  editCategoryDialogIsOpen: false,
  destroyCategoryDialogIsOpen: false,
  action: null,
  selectedRow: null
};

export default CategoryState;
