import React, { useContext, useReducer } from "react";
import ArticleReducer from "context/article/reducer";
import ArticleState from "context/article/state";

const ArticleContext = React.createContext();

const ArticleProvider = ({ children }) => {
  const contextValue = useReducer(ArticleReducer, ArticleState);
  return (
    <ArticleContext.Provider value={contextValue}>
      {children}
    </ArticleContext.Provider>
  );
};
export default ArticleProvider;

export const useArticleProvider = () => {
  const contextValue = useContext(ArticleContext);
  return contextValue;
};
