import React, { useContext, useReducer } from "react";
import CategoryReducer from "context/category/reducer";
import CategoryState from "context/category/state";

const CategoryContext = React.createContext();

const CategoryProvider = ({ children }) => {
  const contextValue = useReducer(CategoryReducer, CategoryState);
  return (
    <CategoryContext.Provider value={contextValue}>
      {children}
    </CategoryContext.Provider>
  );
};
export default CategoryProvider;

export const useCategoryProvider = () => {
  const contextValue = useContext(CategoryContext);
  return contextValue;
};
