import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import MainLayout from "layout/MainLayout";
const Conversations = Loadable(lazy(() => import("views/conversation")));
const Conversation = Loadable(
  lazy(() => import("views/conversation/conversation"))
);
const StartConversation = Loadable(
  lazy(() => import("views/conversation/start"))
);
import AuthRoute from "middlewares/authRoute";
import HasRoles from "middlewares/hasRoles";
import {
  CONVERSATION,
  CONVERSATIONS,
  START_CONVERSATION,
} from "constants/routes";
import {
  SUPER_ADMIN_ROLE,
  PATIENT_ROLE,
  DOCTOR_ROLE,
  LEADER_ROLE,
  ADVISER_ROLE,
} from "constants";

const ConversationRoutes = {
  path: CONVERSATIONS,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: CONVERSATIONS,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            LEADER_ROLE,
            PATIENT_ROLE,
            SUPER_ADMIN_ROLE,
            ADVISER_ROLE,
          ]}
        >
          <Conversations />
        </HasRoles>
      ),
    },
    {
      path: CONVERSATION,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            LEADER_ROLE,
            PATIENT_ROLE,
            SUPER_ADMIN_ROLE,
            ADVISER_ROLE,
          ]}
        >
          <Conversation />
        </HasRoles>
      ),
    },
    {
      path: START_CONVERSATION,
      element: (
        <HasRoles requiredRoles={[PATIENT_ROLE, DOCTOR_ROLE, SUPER_ADMIN_ROLE]}>
          <StartConversation />
        </HasRoles>
      ),
    },
  ],
};

export default ConversationRoutes;
