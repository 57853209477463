import { useState } from "react";
import { useUserProvider } from "context/auth/provider";
import useHttp from "./useHttp";
import { arrayElementsExistInArray } from "utils/helpers";
import {
  SUPER_ADMIN_ROLE,
  DOCTOR_ROLE,
  PATIENT_ROLE,
  LEADER_ROLE,
  LEGAL_ROLE,
  ADVISER_ROLE,
} from "constants";
import {
  ABOUT,
  EDIT_AVATAR,
  EDIT_PROFILE,
  GET_DOCTORS_SUGGESTION,
  GET_PATIENT_DASHBOARD_INFORMATION,
  LEADER,
  RESET_PASSWORD,
  SPEICIAL_DISEASES,
  USERS_LIST,
} from "constants/api";
import {} from "constants";

const useUser = () => {
  const [{ user, isLoggedIn }, dispatch] = useUserProvider();
  const { _get, _post, _patch, _delete } = useHttp();
  const [pending, setPending] = useState(false);
  const [progress, setProgress] = useState(0);
  const isSuperAdmin = isLoggedIn
    ? arrayElementsExistInArray(user?.roles, [SUPER_ADMIN_ROLE])
    : false;
  const isDoctor = isLoggedIn
    ? arrayElementsExistInArray(user?.roles, [DOCTOR_ROLE])
    : false;
  const isAdviser = isLoggedIn
    ? arrayElementsExistInArray(user?.roles, [ADVISER_ROLE])
    : false;
  const isPatient = isLoggedIn
    ? arrayElementsExistInArray(user?.roles, [PATIENT_ROLE])
    : false;
  const isLeader = isLoggedIn
    ? arrayElementsExistInArray(user?.roles, [LEADER_ROLE])
    : false;
  const isLegal = isLoggedIn
    ? arrayElementsExistInArray(user?.roles, [LEGAL_ROLE])
    : false;

  const getSpecialDiseases = async (adaptationForListComponent = true) => {
    setPending(true);
    return await _get(SPEICIAL_DISEASES)
      .then((response) => {
        const {
          data: { data: diseases },
        } = response;
        if (adaptationForListComponent) {
          const list = [];
          diseases.map(({ id, title }) =>
            list.push({ label: title, value: id })
          );
          return list;
        }
        return response.data;
      })
      .finally(() => setPending(false));
  };

  const uploadAvatar = async (data) => {
    enablePending();
    return await _post(EDIT_AVATAR, data, {
      onUploadProgress: function (progressEvent) {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percent);
      },
    }).finally(resetProgressAndPending);
  };

  const deleteAvatar = async () => {
    enablePending();
    return await _delete(
      EDIT_AVATAR,
      {},
      {
        onUploadProgress: function (progressEvent) {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percent);
        },
      }
    ).finally(resetProgressAndPending);
  };

  const getLeaders = async (queryString) => {
    enablePending();
    return await _get(LEADER.concat(queryString.trim())).finally(
      disablePending
    );
  };

  const updateProfile = async (data) => {
    enablePending();
    return await _patch(EDIT_PROFILE, data).finally(disablePending);
  };

  const resetPassword = async (data) => {
    enablePending();
    return await _post(RESET_PASSWORD, data).finally(disablePending);
  };

  const getAbout = async (data) => {
    enablePending();
    return await _get(ABOUT, data).finally(disablePending);
  };

  const updateAbout = async (data) => {
    enablePending();
    return await _post(ABOUT, data).finally(disablePending);
  };

  const getUserFollowings = async () => {
    enablePending();
    return await _get(GET_PATIENT_DASHBOARD_INFORMATION).finally(
      disablePending
    );
  };

  const getDoctorsSuggestion = async () => {
    enablePending();
    return await _get(GET_DOCTORS_SUGGESTION).finally(disablePending);
  };

  const getUsers = async (queryString = "") => {
    enablePending();
    return await _get(USERS_LIST.concat(queryString)).finally(disablePending);
  };

  const enablePending = () => setPending(true);
  const disablePending = () => setPending(false);
  const resetProgress = () => setProgress(0);
  const resetProgressAndPending = () => {
    disablePending();
    resetProgress();
  };

  return {
    user,
    isLoggedIn,
    dispatch,
    pending,
    progress,
    isSuperAdmin,
    isDoctor,
    isAdviser,
    isPatient,
    isLeader,
    isLegal,
    getSpecialDiseases,
    uploadAvatar,
    deleteAvatar,
    updateProfile,
    resetPassword,
    getAbout,
    updateAbout,
    getUserFollowings,
    getDoctorsSuggestion,
    getLeaders,
    getUsers,
  };
};

export default useUser;
