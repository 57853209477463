import axios from "axios";
import { deleteCookie, getCookie } from "utils/cookie";
import { SIGN_IN } from "constants/routes";
import { TOKEN_KEY, CROSS_DOMAIN_COOKIE, LOCALE_KEY, DEFAULT_LOCALE } from "constants";

axios.interceptors.request.use((config) => {
  config.baseURL = process.env.REACT_APP_BACKEND_API;
  config.headers["Accept"] = "application/json";
  config.headers["Accept-Language"] = getCookie(LOCALE_KEY) ?? DEFAULT_LOCALE;
  const token = getCookie(TOKEN_KEY);
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorResponse = error?.response;
    if (errorResponse?.status) {
      if (
        errorResponse?.status === 401 &&
        window.location.pathname !== SIGN_IN
      ) {
        deleteCookie(TOKEN_KEY, { domain: CROSS_DOMAIN_COOKIE });
        window.location.replace(SIGN_IN);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const useHttp = () => {
  const _get = async (url, config = {}) => await axios.get(url, config),
    _post = async (url, data = [], config = {}) =>
      await axios.post(url, data, config),
    _put = async (url, data = [], config = {}) =>
      await axios.put(url, data, config),
    _patch = async (url, data = [], config = {}) =>
      await axios.patch(url, data, config),
    _delete = async (url, data, config = {}) =>
      await axios.delete(url, { ...config, data });

  return {
    _get,
    _post,
    _put,
    _patch,
    _delete,
  };
};

export default useHttp;
