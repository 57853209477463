import { CLEAR_USER, DELETE_AVATAR, SET_AVATAR, SET_USER, UPDATE_USER } from 'constants/actions';

const UserReducer = (state, { type, payload }) => {
    switch (type) {
        case SET_USER:
            return {
                ...state,
                user: payload,
                isLoggedIn: true
            };
        case CLEAR_USER:
            return {
                ...state,
                user: {},
                isLoggedIn: false
            };
        case SET_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar_uri: payload.avatar_uri
                }
            };
        case DELETE_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar_uri: null
                }
            };
        case UPDATE_USER:
            return {
                ...state,
                user: payload
            };
        default:
            return state;
    }
};

export default UserReducer;
