export const USER_PREFIX = "/user";
export const PROFILE_PREFIX = "/profile";
export const ACCOUNT_PREFIX = "/account";
export const FOLLOWER_PREFIX = "/follower";
export const BACKEND_STORAGE = "/storage";
export const LOGIN = `${USER_PREFIX}/login`;
export const REGISTER = `${USER_PREFIX}/register`;
export const REGISTER_PATIENT = `${USER_PREFIX}/register-patient`;
export const REGISTER_MEMBER = `${USER_PREFIX}${PROFILE_PREFIX}${FOLLOWER_PREFIX}/create-members`;
export const LOGOUT = `${USER_PREFIX}/account/logout`;
export const FORGOT_PASSWORd = `${USER_PREFIX}/forget-password`;
export const CHECK_EXIST = `${USER_PREFIX}/check-exist`;
export const SPEICIAL_DISEASES = `${USER_PREFIX}/list-diseases`;
export const USER_DETAILS = `${USER_PREFIX}/account/details`;
export const COUTNRY_LIST = `${USER_PREFIX}/list-country`;
export const MAIN_API = `${process.env.REACT_APP_BACKEND_API}`;
export const PROFIle_API = `${process.env.REACT_APP_PROFILE_BACKEND_API}${USER_PREFIX}/profile`;
export const LIVE_API = process.env.REACT_APP_LIVE_BACKEND_API;
export const ARTICLE = `${MAIN_API}${USER_PREFIX}${PROFILE_PREFIX}/article`;
export const ARTICLE_COMMENTS = `${MAIN_API}${USER_PREFIX}${PROFILE_PREFIX}/article/comment`;
export const COMMENTS = `${MAIN_API}${USER_PREFIX}${PROFILE_PREFIX}/comment`;
export const HIGHLIGHT = `${MAIN_API}${USER_PREFIX}${PROFILE_PREFIX}/highlight`;
export const EDIT_AVATAR = `${USER_PREFIX}/account/avatar`;
export const EDIT_PROFILE = `${USER_PREFIX}/account/edit-info`;
export const RESET_PASSWORD = `${USER_PREFIX}/account/reset-password`;
export const ABOUT = `${USER_PREFIX}${PROFILE_PREFIX}/about-us`;
export const GET_PATIENT_DASHBOARD_INFORMATION = `${USER_PREFIX}${ACCOUNT_PREFIX}/patient`;
export const GET_DOCTORS_SUGGESTION = `${GET_PATIENT_DASHBOARD_INFORMATION}/suggestion`;
export const GET_USERS_BY_UUID = `${USER_PREFIX}/uuid`;
export const ROOM = `${LIVE_API}/room`;
export const DOCTORS = `${MAIN_API}${USER_PREFIX}/list-doctors`;
export const CATEGORIES = `${MAIN_API}${USER_PREFIX}/account/category`;
export const GUEST_CATEGORIES = `${MAIN_API}${USER_PREFIX}/category`;
export const QUESTION_ANSWER = `${MAIN_API}${USER_PREFIX}/account/question`;
export const CONFIGURATION = `${MAIN_API}${USER_PREFIX}/web-config`;
export const LEADER = `${MAIN_API}${USER_PREFIX}${ACCOUNT_PREFIX}/leader`;
export const LEGAL_MEMBERS = `${USER_PREFIX}${PROFILE_PREFIX}${FOLLOWER_PREFIX}`;
export const LEGAL_MEMBERS_AVATAR = `${USER_PREFIX}${PROFILE_PREFIX}${FOLLOWER_PREFIX}/avatar`;
export const GALLERY = `${USER_PREFIX}${ACCOUNT_PREFIX}/gallery`;
export const FILE = `${LIVE_API}/file`;
export const METERED_TURN_SERVERS =
  "https://afzalolatebae.metered.live/api/v1/turn/credentials";

export const SERVICES = `${USER_PREFIX}${ACCOUNT_PREFIX}/price`;

export const CURRENCIES = `${USER_PREFIX}/currency`;

export const USERS_LIST = `${USER_PREFIX}${ACCOUNT_PREFIX}/list-users`;
export const ARTICLES = `${MAIN_API}${USER_PREFIX}/article`;
