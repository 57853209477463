import {
  CLOSE_ADD_LEGAL_MEMBER_DIALOG,
  CLOSE_LEGAL_MEMBER_DESTORY_ALERT_DIALOG,
  DELETE_LEGAL_MEMBER,
  OPEN_ADD_LEGAL_MEMBER_DIALOG,
  OPEN_LEGAL_MEMBER_DESTORY_ALERT_DIALOG,
  SET_LEGAL_ACTION,
  SET_LEGAL_SELECTED_MEMBER,
  STORE_LEGAL_MEMBER,
  STORE_LEGAL_MEMBERS,
} from "constants/actions";

const LegalReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_LEGAL_MEMBERS:
      return {
        ...state,
        members: payload,
      };
    case STORE_LEGAL_MEMBER:
      return {
        ...state,
        members: {
          ...state.members,
          data: [payload, ...state.members.data],
        },
      };
    case SET_LEGAL_ACTION:
      return {
        ...state,
        action: payload,
      };
    case OPEN_LEGAL_MEMBER_DESTORY_ALERT_DIALOG:
      return {
        ...state,
        destroyMemberDialogIsOpen: true,
      };
    case CLOSE_LEGAL_MEMBER_DESTORY_ALERT_DIALOG:
      return {
        ...state,
        destroyMemberDialogIsOpen: false,
      };
    case DELETE_LEGAL_MEMBER:
      const memberIndex = state.members.data.findIndex(
        (member) => member.id === payload
      );
      if (memberIndex >= 0) state.members.data.splice(memberIndex, 1);
      return {
        ...state,
      };
    case SET_LEGAL_SELECTED_MEMBER:
      return {
        ...state,
        selectedRow: payload,
      };
    case OPEN_ADD_LEGAL_MEMBER_DIALOG:
      return {
        ...state,
        addMemberDialogIsOpen: true,
      };
    case CLOSE_ADD_LEGAL_MEMBER_DIALOG:
      return {
        ...state,
        addMemberDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default LegalReducer;
