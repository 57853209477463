import React, { useContext, useReducer } from "react";
import SlideshowReducer from "context/slideShow/reducer";
import SlideshowState from "context/slideShow/state";

const SlideShowContext = React.createContext();

const SlideShowProvider = ({ children }) => {
  const contextValue = useReducer(SlideshowReducer, SlideshowState);
  return (
    <SlideShowContext.Provider value={contextValue}>
      {children}
    </SlideShowContext.Provider>
  );
};
export default SlideShowProvider;

export const useSlideShowProvider = () => {
  const contextValue = useContext(SlideShowContext);
  return contextValue;
};
