// project imports
import Logo from 'components/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Logo LinkProps={{ style: { width: 64, height: 64, display: 'block' } }} style={{ width: '100%', height: '100%' }} />
);

export default LogoSection;
