import React, { useContext, useReducer } from "react";
import LegalReducer from "context/legal/reducer";
import LegalState from "context/legal/state";

const LegalContext = React.createContext();

const LegalProvider = ({ children }) => {
  const contextValue = useReducer(LegalReducer, LegalState);
  return (
    <LegalContext.Provider value={contextValue}>
      {children}
    </LegalContext.Provider>
  );
};
export default LegalProvider;

export const useLegalProvider = () => {
  const contextValue = useContext(LegalContext);
  return contextValue;
};
