import {
  ADD_CATEGORY,
  CLOSE_CREATE_CATEGORY_DIALOG,
  CLOSE_DESTROY_CATEGORY_DIALOG,
  CLOSE_EDIT_CATEGORY_DIALOG,
  DELETE_CATEGORY,
  OPEN_CREATE_CATEGORY_DIALOG,
  OPEN_DESTROY_CATEGORY_DIALOG,
  OPEN_EDIT_CATEGORY_DIALOG,
  SET_CATEGORIES_ACTION,
  SET_SELECTED_CATEGORY,
  STORE_CATEGORIES,
  UPDATE_CATEGORY,
} from "constants/actions";

const CategoryReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: [payload, ...state.categories.data],
        },
      };
    case UPDATE_CATEGORY:
      const updatedCategoryIndex = state.categories.data.findIndex(
        (category) => category.id === state.selectedRow
      );
      if (updatedCategoryIndex >= 0)
        state.categories.data[updatedCategoryIndex] = payload;
      return {
        ...state,
      };
    case DELETE_CATEGORY:
      state.categories.data = state.categories.data.filter(
        (category) => category.id !== state.selectedRow
      );
      return {
        ...state,
      };
    case SET_CATEGORIES_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedRow: payload,
      };
    case OPEN_CREATE_CATEGORY_DIALOG:
      return {
        ...state,
        createCategoryDialogIsOpen: true,
      };
    case CLOSE_CREATE_CATEGORY_DIALOG:
      return {
        ...state,
        createCategoryDialogIsOpen: false,
      };
    case OPEN_EDIT_CATEGORY_DIALOG:
      return {
        ...state,
        editCategoryDialogIsOpen: true,
      };
    case CLOSE_EDIT_CATEGORY_DIALOG:
      return {
        ...state,
        editCategoryDialogIsOpen: false,
      };
    case OPEN_DESTROY_CATEGORY_DIALOG:
      return {
        ...state,
        destroyCategoryDialogIsOpen: true,
      };
    case CLOSE_DESTROY_CATEGORY_DIALOG:
      return {
        ...state,
        destroyCategoryDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
