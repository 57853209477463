const ConversationState = {
  socket: undefined,
  rooms: [],
  room: {},
  call: {},
  peer: {},
  peers: [],
  callAccepted: false,
  callEnded: false,
  stream: null,
  streamType: null,
  callNotificationDialogIsOpen: false,
  callAlertDialogIsOpen: false,
  streamDialogIsOpen: false,
  createRoomDialogIsOpen: false,
  endConversationDialogIsOpen: false,
  restartConversationDialogIsOpen: false,
  addLeaderDialogIsOpen: false,
  filesArchiveDialogIsOpen: false,
};

export default ConversationState;
