import profile from "./profile";
import dashboard from "./dashboard";
import conversation from "./conversation";
import questionAnswer from "./questionAnswer";
import doctors from "./doctors";
import slideShow from "./slideShow";
import services from "./service";
import categories from "./category";

const menuItems = {
  items: [
    dashboard,
    profile,
    conversation,
    questionAnswer,
    doctors,
    slideShow,
    services,
    categories,
  ],
};

export default menuItems;
