import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import useSocket from "hooks/useSocket";
import useUser from "hooks/useUser";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Dialog from "components/Dialog";
import StreamBox from "components/StreamBox";
import { CALL_RECEIVING_RETURNINED_SIGNAL } from "constants/events";

const StreamDialog = () => {
  const theme = useTheme();
  const { user } = useUser();
  const {
    socket,
    state: { streamDialogIsOpen, stream, peers, streamType },
    leaveCall,
    handleOpenStreamDialog,
  } = useSocket();

  const Streams = () => (
    <>
      {peers.map(({ peer, peerId, user }) => (
        <StreamBox
          key={`${streamType}-${peerId}`}
          id={`user-${peerId}-stream`}
          theme={theme}
          user={user}
          peer={peer}
          type={streamType}
        />
      ))}
    </>
  );

  useEffect(() => {
    if (socket && peers.length)
      socket.on(CALL_RECEIVING_RETURNINED_SIGNAL, ({ signal, user }) => {
        const item = peers.find((p) => p.peerId === user.uuid);
        item && item.peer.signal(signal);
        handleOpenStreamDialog();
      });
  }, [peers, socket, streamDialogIsOpen]);

  return (
    <Dialog
      open={streamDialogIsOpen}
      title={<FormattedMessage id="conversation.call.live" />}
      submitButtonLabel={<FormattedMessage id="conversation.call.end" />}
      handleSubmit={leaveCall}
      SubmitButtonProps={{ color: "error" }}
      withCloseButton={false}
      withForm={false}
      withSubmitButton={true}
      fullScreen
    >
      <Grid item xs={12}>
        <Grid container spacing={6}>
          {stream && (
            <StreamBox
              id="auth-user-stream"
              theme={theme}
              user={user}
              isSelf={true}
              stream={stream}
              type={streamType}
            />
          )}
          <Streams />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StreamDialog;
