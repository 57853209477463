window.process = {
  env: { DEBUG: undefined },
  nextTick: function () {
    return null;
  },
};

import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";

// project imports
import * as serviceWorker from "serviceWorker";
import App from "App";

// style + assets
import "assets/scss/style.scss";
import config from "./config";
import ContextProvider from "context/provider";

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ContextProvider>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </ContextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
