import cookie from 'js-cookie';

const getCookie = (key) => {
    const readCookie = cookie.get(key);
    if (readCookie) return readCookie;
    return false;
};

const setCookie = (key, value, options = {}) => {
    cookie.set(key, value, options);
};

const deleteCookie = (key, options = {}) => {
    cookie.remove(key, options);
};

export { getCookie, setCookie, deleteCookie };
