import React, { useContext, useReducer } from 'react';
import UserReducer from 'context/auth/reducer';
import UserState from 'context/auth/state';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
    const contextValue = useReducer(UserReducer, UserState);
    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
export default UserProvider;

export const useUserProvider = () => {
    const contextValue = useContext(UserContext);
    return contextValue;
};
