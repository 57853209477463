import { isMobile, isTablet } from "react-device-detect";

const usePermissions = () => {
  const notificationIsSupported = "Notification" in window;
  const notificationPermissionIsDenied =
    notificationIsSupported && Notification.permission !== "granted";
  const notificationPermissionIsGranted =
    notificationIsSupported && Notification.permission === "granted";
  const userMediaIsSupported =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  const requestNotificationPermission = (
    onGranted = () => {},
    onDenied = () => {}
  ) => {
    if (!notificationIsSupported) {
      alert(
        "Your browser does not support notification, Please use a modern browser like Chrome, Firefox, etc"
      );
      return false;
    }
    Notification.requestPermission().then((permission) =>
      permission === "granted" ? onGranted(permission) : onDenied(permission)
    );
    return true;
  };

  const requestMediaPermission = async (
    contraints = {
      audio: {
        // echoCancellation: false,
        noiseSuppression: false,
        // autoGainControl: false,
      },
    }
  ) =>
    userMediaIsSupported &&
    (await navigator.mediaDevices.getUserMedia({
      ...contraints,
      video: { facingMode: isMobile || isTablet ? "user" : "environment" },
    }));

  return {
    notificationPermissionIsGranted,
    notificationPermissionIsDenied,
    userMediaIsSupported,
    requestNotificationPermission,
    requestMediaPermission,
  };
};

export default usePermissions;
