import { useEffect } from "react";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import useSocket from "hooks/useSocket";
import useUser from "hooks/useUser";
import useNotification from "hooks/useNotification";
import { useSnackbar } from "notistack";
import CallNotificationDialog from "views/conversation/layouts/callNotification";
import StreamDialog from "views/conversation/layouts/streamDialog";
import TimeProgresWithLabel from "./TimeProgress";
import { getTextContent } from "utils/helpers";
import {
  CALL_ENDED,
  CALL_REQUEST,
  CALL_REQUEST_REJECTED,
  END_TEXT_CONVERSATION_EVENT,
  ERROR_EVENT,
  GET_ROOMS_EVENT,
  MESSAGE_SENT_EVENT,
  RESTART_TEXT_CONVERSATION_EVENT,
  ROOMS_READY_EVENT,
} from "constants/events";
import { CONVERSATION, EDIT_PROFILE } from "constants/routes";

const SocketHandler = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    state: { stream, callEnded, call, rooms, streamDialogIsOpen },
    socket,
    connect,
    addMessage,
    updateRoomStatus,
    handleOpenCallNotificationDialog,
    setStreamType,
    setCall,
    handleCloseCallAlertDialog,
    handleCloseCallNotificationDialog,
    handleDestroyAuthUserPeer,
    endStream,
    handleDestroyPeer,
    storeRooms,
    roomsObjectBuilder,
    handleGetUserMediaPermission,
  } = useSocket();
  const { user, isLoggedIn } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { send } = useNotification();
  const receivedCallRequest =
    isLoggedIn &&
    Object.keys(call).length &&
    rooms.find((room) => room.id === call.room_id) &&
    !streamDialogIsOpen;

  const handleGetRooms = () => {
    socket.emit(GET_ROOMS_EVENT, { user_id: user.uuid });
    socket.on(ROOMS_READY_EVENT, async ({ rooms }) =>
      storeRooms(roomsObjectBuilder(rooms))
    );
  };

  const onNewMessage = ({ message, showNotification }) => {
    addMessage(message);
    if (showNotification) {
      const title = intl.formatMessage({
        id: "conversation.receivedNewMessage",
      });
      const body = `${getTextContent(message.body).substring(0, 50)}...`;
      const notification = send(title, { body });
      notification.onclick = () =>
        navigate(CONVERSATION.replace(":id", message?.room_id));
    }
  };

  const handleEndConversation = ({ room }) => {
    enqueueSnackbar(<FormattedMessage id="conversation.ended" />, {
      variant: "info",
    });
    updateRoomStatus(room.status);
  };

  const handleRestartConversation = ({ room }) => {
    enqueueSnackbar(<FormattedMessage id="conversation.restarted" />, {
      variant: "info",
    });
    updateRoomStatus(room.status);
  };

  const handleRejectedCall = () => {
    enqueueSnackbar(<FormattedMessage id="conversation.call.rejected" />, {
      variant: "info",
    });
    handleCloseCallAlertDialog();
    handleCloseCallNotificationDialog();
    handleDestroyAuthUserPeer();
    endStream();
  };
  const handleRemoveUserPeer = ({ user }) => {
    enqueueSnackbar(
      `${
        user?.full_name ?? user?.user_name ?? user?.mobile
      } ${intl.formatMessage({ id: "conversation.user.leaved" })}`,
      { variant: "info" }
    );
    handleDestroyPeer(user.uuid);
  };

  useEffect(() => {
    if (isLoggedIn)
      if (
        !user?.sex ||
        !user?.user_name ||
        !user?.first_name ||
        !user?.last_name
      ) {
        enqueueSnackbar(intl.formatMessage({ id: "auth.completeProfile" }), {
          variant: "warning",
        });
        navigate(EDIT_PROFILE);
      }
  }, []);

  useEffect(() => {
    if (isLoggedIn && !socket) connect();
  }, [isLoggedIn]);

  useEffect(() => {
    if (receivedCallRequest) {
      !stream && handleGetUserMediaPermission();
      handleOpenCallNotificationDialog();
    }
  }, [receivedCallRequest]);

  useEffect(() => {
    if (socket) {
      handleGetRooms();
      socket.on(MESSAGE_SENT_EVENT, onNewMessage);
      socket.on(END_TEXT_CONVERSATION_EVENT, handleEndConversation);
      socket.on(RESTART_TEXT_CONVERSATION_EVENT, handleRestartConversation);
      socket.on(CALL_REQUEST, ({ user, signal, type, room_id, isPrivate }) => {
        setStreamType(type);
        setCall({
          isReceivedCall: true,
          isPrivate,
          user,
          signal,
          room_id,
        });
        isPrivate && handleOpenCallNotificationDialog();
      });

      socket.on(CALL_REQUEST_REJECTED, handleRejectedCall);

      socket.on(CALL_ENDED, handleRemoveUserPeer);
      socket.on(ERROR_EVENT, (errors) => {
        enqueueSnackbar(<FormattedMessage id="global.errorHasOccurred" />, {
          variant: "error",
        });
        console.error(errors);
      });
    }
    // return disconnect;
  }, [socket]);

  return (
    <>
      <CallNotificationDialog />
      <StreamDialog />
      {callEnded && (
        <TimeProgresWithLabel
          duration={3}
          onEnd={() => window.location.reload()}
          title={
            <FormattedMessage id="conversation.call.ended.reloadMessage" />
          }
        />
      )}
    </>
  );
};

export default SocketHandler;
