import {
  ADD_MESSAGE,
  CLOSE_ADD_LEADER_DIALOG,
  CLOSE_CALL_ALERT_DIALOG,
  CLOSE_CALL_NOTIFICATION_DIALOG,
  CLOSE_CREATE_ROOM_DIALOG,
  CLOSE_END_CONVERSATION_DIALOG,
  CLOSE_FILES_ARCHIVE_DIALOG,
  CLOSE_RESTART_CONVERSATION_DIALOG,
  CLOSE_STREAM_DIALOG,
  DESTROY_PEER,
  END_STREAM,
  OPEN_ADD_LEADER_DIALOG,
  OPEN_CALL_ALERT_DIALOG,
  OPEN_CALL_NOTIFICATION_DIALOG,
  OPEN_CREATE_ROOM_DIALOG,
  OPEN_END_CONVERSATION_DIALOG,
  OPEN_FILES_ARCHIVE_DIALOG,
  OPEN_RESTART_CONVERSATION_DIALOG,
  OPEN_STREAM_DIALOG,
  SET_CALL,
  SET_CALL_ACCEPTED,
  SET_CALL_ENDED,
  SET_PEER,
  SET_PEERS,
  SET_SOCKET,
  SET_STREAM,
  SET_STREAM_TYPE,
  STORE_MESSAGES,
  STORE_ROOM,
  STORE_ROOMS,
  UNSET_SOCKET,
  UPDATE_ROOM,
  UPDATE_ROOM_STATUS,
} from "constants/actions";

const ConversationReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SOCKET:
      return {
        ...state,
        socket: payload,
      };
    case UNSET_SOCKET:
      return {
        ...state,
        socket: undefined,
      };
    case STORE_ROOMS:
      return {
        ...state,
        rooms: payload,
      };
    case STORE_ROOM:
      payload.messages = {
        results: [],
      };
      return {
        ...state,
        room: payload,
      };
    case STORE_MESSAGES:
      if (Object.keys(state.room).length) {
        payload.results = Array.from(
          new Set([...state.room.messages.results, ...payload?.results])
        );
        return {
          ...state,
          room: {
            ...state.room,
            messages: payload,
          },
        };
      }
      return {
        ...state,
      };
    case UPDATE_ROOM:
      const roomIndex = state.rooms.findIndex((room) => room.id === payload.id);
      if (roomIndex >= 0) state.rooms[roomIndex] = payload;
      if (state.room) state.room = payload;
      return {
        ...state,
      };
    case UPDATE_ROOM_STATUS:
      if (state.room) state.room.status = payload;
      return {
        ...state,
      };
    case ADD_MESSAGE:
      const roomIndexToAddMessage = state.rooms.findIndex(
        (item) => item.id === payload.room_id
      );
      if (roomIndexToAddMessage >= 0) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = payload.body;
        const messageText = tempDiv.innerText;
        state.rooms[roomIndexToAddMessage].body = messageText;
      }
      if (Object.keys(state.room).length && state.room.id === payload.room_id) {
        state.room.messages.results = Array.from(
          new Set([payload, ...state.room.messages.results])
        );
        state.room.messages.total = state.room.messages.total + 1;
      }
      return {
        ...state,
      };
    case OPEN_CREATE_ROOM_DIALOG:
      return {
        ...state,
        createRoomDialogIsOpen: true,
      };
    case CLOSE_CREATE_ROOM_DIALOG:
      return {
        ...state,
        createRoomDialogIsOpen: false,
      };
    case OPEN_END_CONVERSATION_DIALOG:
      return {
        ...state,
        endConversationDialogIsOpen: true,
      };
    case CLOSE_END_CONVERSATION_DIALOG:
      return {
        ...state,
        endConversationDialogIsOpen: false,
      };
    case OPEN_RESTART_CONVERSATION_DIALOG:
      return {
        ...state,
        restartConversationDialogIsOpen: true,
      };
    case CLOSE_RESTART_CONVERSATION_DIALOG:
      return {
        ...state,
        restartConversationDialogIsOpen: false,
      };
    case OPEN_ADD_LEADER_DIALOG:
      return {
        ...state,
        addLeaderDialogIsOpen: true,
      };
    case CLOSE_ADD_LEADER_DIALOG:
      return {
        ...state,
        addLeaderDialogIsOpen: false,
      };
    case OPEN_FILES_ARCHIVE_DIALOG:
      return {
        ...state,
        filesArchiveDialogIsOpen: true,
      };
    case CLOSE_FILES_ARCHIVE_DIALOG:
      return {
        ...state,
        filesArchiveDialogIsOpen: false,
      };
    case SET_STREAM:
      return {
        ...state,
        stream: payload,
      };
    case END_STREAM:
      return {
        ...state,
        call: {},
        peer: {},
        peers: [],
        callAccepted: false,
        callEnded: true,
        streams: [],
        streamType: null,
        callNotificationDialogIsOpen: false,
        callAlertDialogIsOpen: false,
        streamDialogIsOpen: false,
        ...payload,
      };
    case SET_CALL:
      return {
        ...state,
        call: payload,
      };
    case SET_STREAM_TYPE:
      return {
        ...state,
        streamType: payload,
      };
    case SET_PEERS:
      return {
        ...state,
        peers: payload,
      };
    case SET_PEER:
      return {
        ...state,
        peer: payload,
      };
    case DESTROY_PEER:
      const peerIndex = state.peers.findIndex(
        (peer) => peer.peerId === payload
      );
      if (peerIndex >= 0) {
        state.peers[peerIndex].peer.destroy();
        state.peers.splice(peerIndex, 1);
      }
      return {
        ...state,
      };
    case SET_CALL_ACCEPTED:
      return {
        ...state,
        callAccepted: true,
      };
    case SET_CALL_ENDED:
      return {
        ...state,
        callEnded: true,
      };
    case OPEN_CALL_NOTIFICATION_DIALOG:
      return {
        ...state,
        callNotificationDialogIsOpen: true,
      };
    case CLOSE_CALL_NOTIFICATION_DIALOG:
      return {
        ...state,
        callNotificationDialogIsOpen: false,
      };
    case OPEN_STREAM_DIALOG:
      return {
        ...state,
        streamDialogIsOpen: true,
      };
    case CLOSE_STREAM_DIALOG:
      return {
        ...state,
        streamDialogIsOpen: false,
      };
    case OPEN_CALL_ALERT_DIALOG:
      return {
        ...state,
        callAlertDialogIsOpen: true,
      };
    case CLOSE_CALL_ALERT_DIALOG:
      return {
        ...state,
        callAlertDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default ConversationReducer;
