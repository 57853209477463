import usePermissions from "./usePermissions";
import { LOGO_URL } from "constants";

const useNotification = () => {
  const serviceWorkerIsSupported = "serviceWorker" in navigator;
  const { notificationPermissionIsGranted } = usePermissions();
  const send = (title = "", options = {}) =>
    notificationPermissionIsGranted &&
    new Notification(title, {
      icon: LOGO_URL,
      ...options,
    });
  return {
    serviceWorkerIsSupported,
    send,
  };
};

export default useNotification;
