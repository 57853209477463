import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import GuestRoute from "middlewares/guestRoute";
import { AUTH, FORGOT_PASSWORD, REGISTER, REQUEST, SIGN_IN, VERFIY } from "constants/routes";

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import("views/authentication/Login")));
const AuthVerify = Loadable(lazy(() => import("views/authentication/Verify")));
const AuthRegister = Loadable(
  lazy(() => import("views/authentication/Register"))
);
const AuthRequest = Loadable(
  lazy(() => import("views/authentication/Request"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/authentication/ForgotPassword"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: AUTH,
  element: (
    <GuestRoute>
      <MinimalLayout />
    </GuestRoute>
  ),
  children: [
    {
      path: SIGN_IN,
      element: <AuthLogin />,
    },
    {
      path: VERFIY,
      element: <AuthVerify />,
    },
    {
      path: REGISTER,
      element: <AuthRegister />,
    },
    {
      path: REQUEST,
      element: <AuthRequest />,
    },
    {
      path: FORGOT_PASSWORD,
      element: <AuthForgotPassword />,
    },
  ],
};

export default AuthenticationRoutes;
