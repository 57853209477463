import { alpha, useTheme } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';

const Avatar = ({ path, alt = '', customStyles = {}, withZoomEffect = true, zoomValue = 1.4, children, ...props }) => {
    const { palette } = useTheme();
    const styles = {
        border: '4px solid',
        borderColor: alpha(palette.primary.main, 0.7),
        overflow: 'hidden',
        ...(withZoomEffect && {
            '& img': {
                objectFit: 'cover',
                transition: '1s ease'
            },
            '& img:hover': {
                transform: `scale(${zoomValue})`
            }
        }),
        ...customStyles
    };
    return <MuiAvatar src={path} alt={alt} sx={styles} {...props} />;
};

export default Avatar;
