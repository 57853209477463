import MuiButton from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

const Button = ({ type = 'submit', variant = 'contained', textColor = '#fff', className, ...props }) => (
    <MuiButton type={type} variant={variant} className={className} sx={{ color: textColor, ...props.sx }} {...props}>
        {props.disabled ? (
            <>
                <FormattedMessage id="global.pending" />
                <span className="animated-fade animated-infinite">...</span>
            </>
        ) : (
            props.children
        )}
    </MuiButton>
);

export default Button;
