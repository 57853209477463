import {
  CLOSE_CREATE_SLIDE_DIALOG,
  CLOSE_DESTROY_SLIDE_DIALOG,
  CLOSE_EDIT_SLIDE_DIALOG,
  DELETE_SLIDE,
  OPEN_CREATE_SLIDE_DIALOG,
  OPEN_DESTROY_SLIDE_DIALOG,
  OPEN_EDIT_SLIDE_DIALOG,
  SET_SELECTED_SLIDE,
  SET_SLIDE_SHOW_ACTION,
  STORE_SLIDE,
  STORE_SLIDES,
  UPDATE_SLIDE,
} from "constants/actions";

const SlideshowReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_SLIDES:
      return {
        ...state,
        slides: payload,
      };
    case STORE_SLIDE:
      return {
        ...state,
        slides: [payload, ...state.slides],
      };
    case UPDATE_SLIDE:
      const prevSlideIndex = state.slides.findIndex(
        (slide) => slide.id === state.selectedRow
      );
      if (prevSlideIndex >= 0) state.slides[prevSlideIndex] = payload;
      return {
        ...state,
        selectedRow: null,
      };
    case DELETE_SLIDE:
      const slideIndexToDelete = state.slides.findIndex(
        (slide) => slide.id === state.selectedRow
      );
      if (slideIndexToDelete >= 0) state.slides.splice(slideIndexToDelete, 1);
      return {
        ...state,
      };
    case SET_SLIDE_SHOW_ACTION:
      return {
        ...state,
        action: payload,
      };
    case OPEN_CREATE_SLIDE_DIALOG:
      return {
        ...state,
        createSlideDialogIsOpen: true,
      };
    case CLOSE_CREATE_SLIDE_DIALOG:
      return {
        ...state,
        createSlideDialogIsOpen: false,
      };
    case OPEN_EDIT_SLIDE_DIALOG:
      return {
        ...state,
        editSlideDialogIsOpen: true,
      };
    case CLOSE_EDIT_SLIDE_DIALOG:
      return {
        ...state,
        editSlideDialogIsOpen: false,
      };
    case OPEN_DESTROY_SLIDE_DIALOG:
      return {
        ...state,
        destroySlideDialogIsOpen: true,
      };
    case CLOSE_DESTROY_SLIDE_DIALOG:
      return {
        ...state,
        destroySlideDialogIsOpen: false,
      };
    case SET_SELECTED_SLIDE:
      return {
        ...state,
        selectedRow: payload,
      };
    default:
      return state;
  }
};

export default SlideshowReducer;
