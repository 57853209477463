// assets
import { LEGAL_DOCTORS } from "constants/routes";
import { FormattedMessage } from "react-intl";
import { IconUsers } from "@tabler/icons";
import { LEGAL_ROLE } from "constants";

// constant
const icons = { IconUsers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const doctors = {
  id: "doctors",
  title: <FormattedMessage id="sidebar.doctors.title" />,
  type: "group",
  roles: [LEGAL_ROLE],
  children: [
    {
      id: "doctors-list",
      title: <FormattedMessage id="sidebar.doctors.manage" />,
      type: "item",
      url: LEGAL_DOCTORS,
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
  ],
};

export default doctors;
