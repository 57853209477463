import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import MainLayout from "layout/MainLayout";
const Categories = Loadable(lazy(() => import("views/categories/index")));
import AuthRoute from "middlewares/authRoute";
import HasRoles from "middlewares/hasRoles";
import { CATEGORIES } from "constants/routes";
import { SUPER_ADMIN_ROLE, LEGAL_ROLE } from "constants";

const CategoriesRoutes = {
  path: CATEGORIES,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: CATEGORIES,
      element: (
        <HasRoles requiredRoles={[SUPER_ADMIN_ROLE, LEGAL_ROLE]}>
          <Categories />
        </HasRoles>
      ),
    },
  ],
};

export default CategoriesRoutes;
