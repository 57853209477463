export const DASHBOARD = "/";
export const FRONTEND_HOME = process.env.REACT_APP_FRONTEND_URL;
export const AUTH = "/auth";
export const SIGN_IN = `${AUTH}/login`;
export const VERFIY = `${AUTH}/verify`;
export const REGISTER = `${AUTH}/register`;
export const REQUEST = `${AUTH}/request`;
export const FORGOT_PASSWORD = `${AUTH}/forgot-password`;
export const ARTICLES = "/articles";
export const CREATE_ARTICLE = "/articles/create";
export const EDIT_ARTICLE = "/articles/edit/:slug";
export const ARTICLE_COMMENTS = "/articles/comments/:slug";
export const HIGHLIGHTS = "/highlights";
export const ABOUT = "/about";
export const PROFILE = "/profile";
export const EDIT_PROFILE = `${PROFILE}/edit`;
export const CONVERSATIONS = "/conversations";
export const CONVERSATION = "/conversations/:id";
export const START_CONVERSATION = "/conversations/start";
export const QUESTION_ANSWER = "/question-answer";
export const CREATE_QUESTION = `${QUESTION_ANSWER}/create`;
export const EDIT_QUESTION = `${QUESTION_ANSWER}/edit/:id`;
export const LEGAL_DOCTORS = "/doctors";
export const CREATE_LEGAL_DOCTOR = `${LEGAL_DOCTORS}/create`;
export const EDIT_LEGAL_DOCTOR = `${LEGAL_DOCTORS}/edit/:id`;
export const SLIDE_SHOW = "/slide-show";
export const NOT_FOUND_ERROR = "/404";
export const SERVER_ERROR = "/500";
export const SERVICE_UNAVAILABLE = "/503";
export const SERVICES = "/services";
export const CATEGORIES = "/catgories";
