import {
  CLOSE_QUESTION_DESTROY_DIALOG,
  DELETE_QUESTION,
  OPEN_QUESTION_DESTROY_DIALOG,
  SET_QUESTION_ACTION,
  SET_QUESTION_SELECTED_ROW,
  STORE_QUESTIONS,
} from "constants/actions";

const QuestionAnswerReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_QUESTIONS:
      return {
        ...state,
        questionAnswers: payload,
      };
    case OPEN_QUESTION_DESTROY_DIALOG:
      return {
        ...state,
        destroyDialogIsOpen: true,
      };
    case CLOSE_QUESTION_DESTROY_DIALOG:
      return {
        ...state,
        destroyDialogIsOpen: false,
      };
    case SET_QUESTION_SELECTED_ROW:
      return {
        ...state,
        selectedRow: payload,
      };
    case DELETE_QUESTION:
      state.questionAnswers.data = state.questionAnswers.data.filter(
        (item) => item.slug !== payload
      );
      return {
        ...state,
      };
    case SET_QUESTION_ACTION:
      return {
        ...state,
        action: payload,
      };
    default:
      return state;
  }
};

export default QuestionAnswerReducer;
