import {
  ADD_HIGHLIGHT,
  CLEAR_HIGHLIGHTS,
  CLOSE_CREATE_HIGHLiGHT_DIALOG,
  CLOSE_DESTROY_CONFIRM_DIALOG,
  CLOSE_EDIT_HIGHLiGHT_DIALOG,
  OPEN_CREATE_HIGHLiGHT_DIALOG,
  OPEN_DESTROY_CONFIRM_DIALOG,
  OPEN_EDIT_HIGHLiGHT_DIALOG,
  REMOVE_HIGHLIGHT,
  STORE_HIGHLIGHT,
  STORE_HIGHLIGHTS,
  SET_HIGHLIGHT_ACTION,
  SET_HIGHLIGHT_SELECTED_ROW,
  UPDATE_HIGHLIGHT,
} from "constants/actions";

const HighlightReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_HIGHLIGHTS:
      return {
        ...state,
        highlights: payload,
      };
    case STORE_HIGHLIGHT:
      return {
        ...state,
        highlight: payload,
      };
    case ADD_HIGHLIGHT:
      if (payload.forList)
        return {
          ...state,
          highlights: {
            ...state.highlights,
            data: [payload.highlight, ...state.highlights.data],
          },
        };
      else {
        state.highlights.data.unshift(payload.highlight);
        return {
          ...state,
        };
      }
    case UPDATE_HIGHLIGHT:
      const highlightIndex = state.highlights.data.findIndex(
        (item) => item.id === payload.id
      );
      if (highlightIndex >= 0) state.highlights.data[highlightIndex] = payload;
      return {
        ...state,
      };
    case REMOVE_HIGHLIGHT:
      state.highlights.data = state.highlights.data.filter(
        (item) => item.slug != state.selectedRow
      );
      return {
        ...state,
      };
    case CLEAR_HIGHLIGHTS:
      return {
        ...state,
        highlights: [],
        highlight: {},
        createDialogIsOpen: false,
        editDialogIsOpen: false,
        confirmDestroyDialogIsOpen: false,
      };
    case OPEN_CREATE_HIGHLiGHT_DIALOG:
      return {
        ...state,
        createDialogIsOpen: true,
      };
    case CLOSE_CREATE_HIGHLiGHT_DIALOG:
      return {
        ...state,
        createDialogIsOpen: false,
      };
    case OPEN_EDIT_HIGHLiGHT_DIALOG:
      return {
        ...state,
        editDialogIsOpen: true,
      };
    case CLOSE_EDIT_HIGHLiGHT_DIALOG:
      return {
        ...state,
        editDialogIsOpen: false,
      };
    case OPEN_DESTROY_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDestroyDialogIsOpen: true,
      };
    case CLOSE_DESTROY_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDestroyDialogIsOpen: false,
      };
    case SET_HIGHLIGHT_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_HIGHLIGHT_SELECTED_ROW:
      return {
        ...state,
        selectedRow: payload,
      };
    default:
      return state;
  }
};

export default HighlightReducer;
