import { FormattedMessage } from "react-intl";
import useSocket from "hooks/useSocket";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Dialog from "components/Dialog";
import Avatar from "components/Avatar";
import Typography from "components/Typography";
import Tooltip from "@mui/material/Tooltip";
import { getRemoteStoragePath } from "utils/helpers";
import { IconPhone, IconPhoneOff } from "@tabler/icons";

const CallNotificationDialog = () => {
  const theme = useTheme();
  const {
    state: { callNotificationDialogIsOpen, call, streamType },
    pending,
    joinCall,
    rejectCall,
    handleCloseCallNotificationDialog,
  } = useSocket();

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") return;
    handleCloseCallNotificationDialog();
  };

  return (
    <Dialog
      open={callNotificationDialogIsOpen}
      handleClose={handleClose}
      maxWidth="sm"
      PaperProps={{ sx: { overflowY: "visible" } }}
      withFooter={false}
      withForm={false}
    >
      <Grid item xs={12}>
        <Avatar
          path={
            call?.user?.avatar_uri
              ? getRemoteStoragePath(call?.user?.avatar_uri)
              : null
          }
          alt="Contact avatar"
          customStyles={{
            width: 128,
            height: 128,
            position: "absolute",
            top: "-32%",
            right: 0,
            left: 0,
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Grid>
      <Grid item xs={12} textAlign="center" mt={7}>
        <Typography variant="h3">
          {call?.user?.full_name ?? call?.user?.mobile ?? call?.user?.full_name}
        </Typography>
        <Typography my={1} fontWeight="bold">
          {streamType === "video" ? (
            <FormattedMessage id="conversation.videoCall" />
          ) : (
            <FormattedMessage id="conversation.voiceCall" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={6}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item>
            <Tooltip title={<FormattedMessage id="conversation.call.answer" />}>
              <span>
                <IconButton onClick={joinCall} disabled={pending}>
                  <IconPhone color={theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="conversation.call.reject" />}>
              <span>
                <IconButton onClick={rejectCall} disabled={pending}>
                  <IconPhoneOff color={theme.palette.error.main} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CallNotificationDialog;
