import { useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import useSocket from "hooks/useSocket";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Video from "./Video";
import Audio from "./Audio";
import Typography from "./Typography";
import Avatar from "./Avatar";
import { getRemoteStoragePath } from "utils/helpers";

const StreamBox = ({
  user = {},
  peer = {},
  stream = {},
  type = "video",
  theme = {},
  isSelf = false,
  children,
  ...props
}) => {
  const { disablePending } = useSocket();
  const mediaRef = useRef(null);

  useEffect(() => {
    if (!mediaRef.current || !peer) return;
    if (isSelf) {
      mediaRef.current.srcObject = stream;
      mediaRef.current.volume = 0;
      return;
    }
    peer?.on("stream", (stream) => {
      disablePending();
      mediaRef.current.srcObject = stream;
    });
  }, [peer, mediaRef]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} {...props}>
      <Box
        sx={{
          ...(type === "video" && {
            width: "100%",
            height: { xs: "18rem", sm: "16rem", md: "14rem", xl: "25rem" },
          }),
          "& video": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 5,
            border: `3px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <>
          {type === "video" ? (
            <Video videoRef={mediaRef} playsInline autoPlay />
          ) : (
            <Audio audioRef={mediaRef} style={{ display: "none" }} />
          )}
        </>
      </Box>
      <Grid item xs={12} mt={2}>
        <Grid container textAlign="center" spacing={2}>
          {type === "voice" && (
            <Grid item xs={12}>
              <Avatar
                path={
                  user?.avatar_uri
                    ? getRemoteStoragePath(user.avatar_uri, true)
                    : null
                }
                customStyles={{ width: 128, height: 128, margin: "0 auto" }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h3" mx={1}>
              {isSelf ? (
                <FormattedMessage id="global.you" />
              ) : (
                user.full_name ?? user.user_name ?? user.mobile
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StreamBox;
