// assets
import { FormattedMessage } from "react-intl";
import { SLIDE_SHOW } from "constants/routes";
import { LEGAL_ROLE } from "constants";
import { IconSlideshow } from "@tabler/icons";

// constant
const icons = { IconSlideshow };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const slideShow = {
  id: "slide-show",
  title: <FormattedMessage id="sidebar.slideshow.title" />,
  type: "group",
  roles: [LEGAL_ROLE],
  children: [
    {
      id: "slide-show-index",
      title: <FormattedMessage id="sidebar.slideshow.manage" />,
      type: "item",
      url: SLIDE_SHOW,
      icon: icons.IconSlideshow,
      breadcrumbs: false,
    },
  ],
};

export default slideShow;
