import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/material";
import DialogComponent from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "./Button";

const Dialog = ({
  children,
  open = false,
  title = "",
  handleClose = () => {},
  handleSubmit = () => {},
  withForm = true,
  FormProps = {},
  withFooter = true,
  closeButtonLabel = <FormattedMessage id="global.close" />,
  submitButtonLabel = <FormattedMessage id="global.confirm" />,
  fullWidth = true,
  pending = false,
  DialogContentProps = {},
  withSubmitButton = true,
  withCloseButton = true,
  SubmitButtonProps = {},
  CloseButtonProps = {},
  ...props
}) => {
  const theme = useTheme();
  return (
    <DialogComponent
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...props}
    >
      {title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      {withForm ? (
        <form onSubmit={handleSubmit} {...FormProps}>
          <DialogContent {...DialogContentProps}>{children}</DialogContent>
          {withFooter && (
            <DialogActions>
              {withCloseButton && (
                <Button
                  type="button"
                  textColor={theme.palette.common.black}
                  variant="text"
                  onClick={handleClose}
                  disabled={pending}
                  {...CloseButtonProps}
                >
                  {closeButtonLabel}
                </Button>
              )}
              {withSubmitButton && (
                <Button type="submit" disabled={pending} {...SubmitButtonProps}>
                  {submitButtonLabel}
                </Button>
              )}
            </DialogActions>
          )}
        </form>
      ) : (
        <>
          <DialogContent {...DialogContentProps}>{children}</DialogContent>
          {withFooter && (
            <DialogActions>
              {withCloseButton && (
                <Button
                  type="button"
                  textColor={theme.palette.common.black}
                  variant="text"
                  onClick={handleClose}
                  disabled={pending}
                  {...CloseButtonProps}
                >
                  {closeButtonLabel}
                </Button>
              )}
              {withSubmitButton && (
                <Button
                  type="button"
                  onClick={handleSubmit}
                  disabled={pending}
                  {...SubmitButtonProps}
                >
                  {submitButtonLabel}
                </Button>
              )}
            </DialogActions>
          )}
        </>
      )}
    </DialogComponent>
  );
};

export default Dialog;
