import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import ConversationRoutes from "./ConversationRoutes";
import QuestionAnswerRoutes from "./QuestionAnswerRoutes";
import ErrorsRoutes from "./ErrorsRoutes";
import ArticleRoutes from "./ArticleRoutes";
import LegalRoutes from "./LegalRoutes";
import ServicesRoutes from "./ServiceRoutes";
import CategoryRoutes from "./CategoryRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    ArticleRoutes,
    AuthenticationRoutes,
    ConversationRoutes,
    QuestionAnswerRoutes,
    LegalRoutes,
    ServicesRoutes,
    CategoryRoutes,
    ErrorsRoutes,
  ]);
}
