import React, { useContext, useReducer } from 'react';
import ConversationReducer from 'context/conversation/reducer';
import ConversationState from 'context/conversation/state';

const ConversationContext = React.createContext();

const ConversationProvider = ({ children }) => {
    const contextValue = useReducer(ConversationReducer, ConversationState);
    return <ConversationContext.Provider value={contextValue}>{children}</ConversationContext.Provider>;
};
export default ConversationProvider;

export const useConversationProvider = () => {
    const contextValue = useContext(ConversationContext);
    return contextValue;
};
