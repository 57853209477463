import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import AuthRoute from "middlewares/authRoute";
import HasRoles from "middlewares/hasRoles";
// project imports
import Loadable from "ui-component/Loadable";
const Articles = Loadable(lazy(() => import("views/article")));
const CreateArticle = Loadable(
  lazy(() => import("views/article/forms/create"))
);
const EditArticle = Loadable(lazy(() => import("views/article/forms/edit")));
const ArticleComments = Loadable(lazy(() => import("views/article/comments")));
const Highlights = Loadable(lazy(() => import("views/highlight")));
import {
  ARTICLES,
  ARTICLE_COMMENTS,
  CREATE_ARTICLE,
  EDIT_ARTICLE,
  HIGHLIGHTS,
} from "constants/routes";
import {
  DOCTOR_ROLE,
  ADVISER_ROLE,
  SUPER_ADMIN_ROLE,
  LEGAL_ROLE,
} from "constants";
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ArticleRoutes = {
  path: "/",
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: ARTICLES,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            ADVISER_ROLE,
            SUPER_ADMIN_ROLE,
            LEGAL_ROLE,
          ]}
        >
          <Articles />
        </HasRoles>
      ),
    },
    {
      path: CREATE_ARTICLE,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            ADVISER_ROLE,
            SUPER_ADMIN_ROLE,
            LEGAL_ROLE,
          ]}
        >
          <CreateArticle />
        </HasRoles>
      ),
    },
    {
      path: EDIT_ARTICLE,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            ADVISER_ROLE,
            SUPER_ADMIN_ROLE,
            LEGAL_ROLE,
          ]}
        >
          <EditArticle />
        </HasRoles>
      ),
    },
    {
      path: ARTICLE_COMMENTS,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            ADVISER_ROLE,
            SUPER_ADMIN_ROLE,
            LEGAL_ROLE,
          ]}
        >
          <ArticleComments />
        </HasRoles>
      ),
    },
    {
      path: HIGHLIGHTS,
      element: (
        <HasRoles
          requiredRoles={[
            DOCTOR_ROLE,
            ADVISER_ROLE,
            SUPER_ADMIN_ROLE,
            LEGAL_ROLE,
          ]}
        >
          <Highlights />
        </HasRoles>
      ),
    },
  ],
};

export default ArticleRoutes;
