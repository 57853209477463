// assets
import { IconDashboard } from '@tabler/icons';
import { DASHBOARD } from 'constants/routes';
import { FormattedMessage } from 'react-intl';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="sidebar.dashboard.title" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="sidebar.dashboard.title" />,
            type: 'item',
            url: DASHBOARD,
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
