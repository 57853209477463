import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import useUser from "hooks/useUser";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "./Button";
import { DASHBOARD, SIGN_IN } from "constants/routes";
import { IconArrowRight } from "@tabler/icons";

const BackButton = ({
  type = "icon",
  IconProps,
  ButtonProps,
  label = <FormattedMessage id="global.back" />,
}) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUser();
  const buttonHref = isLoggedIn ? DASHBOARD : SIGN_IN;

  return (
    <>
      {type === "icon" ? (
        <Tooltip title={label}>
          <IconButton onClick={() => navigate(buttonHref)} {...ButtonProps}>
            <IconArrowRight {...IconProps} />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          onClick={() => navigate(buttonHref)}
          startIcon={<IconArrowRight {...IconProps} />}
          {...ButtonProps}
        >
          {label}
        </Button>
      )}
    </>
  );
};

export default BackButton;
