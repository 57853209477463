import React, { useContext, useReducer } from 'react';
import HighlightReducer from 'context/highlight/reducer';
import HighlightState from 'context/highlight/state';

const HighlightContext = React.createContext();

const HighlightProvider = ({ children }) => {
    const contextValue = useReducer(HighlightReducer, HighlightState);
    return <HighlightContext.Provider value={contextValue}>{children}</HighlightContext.Provider>;
};
export default HighlightProvider;

export const useHighlightProvider = () => {
    const contextValue = useContext(HighlightContext);
    return contextValue;
};
