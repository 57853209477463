// assets
import { FormattedMessage } from "react-intl";
import { CONVERSATIONS } from "constants/routes";
import { IconMessage } from "@tabler/icons";
import { SUPER_ADMIN_ROLE } from "constants";
import {
  DOCTOR_ROLE,
  LEADER_ROLE,
  PATIENT_ROLE,
  ADVISER_ROLE,
} from "constants";

// constant
const icons = { IconMessage };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const conversation = {
  id: "conversation",
  title: <FormattedMessage id="sidebar.advice.title" />,
  type: "group",
  roles: [
    SUPER_ADMIN_ROLE,
    DOCTOR_ROLE,
    LEADER_ROLE,
    PATIENT_ROLE,
    ADVISER_ROLE,
  ],
  children: [
    {
      id: "conversation",
      title: <FormattedMessage id="sidebar.conversation.title" />,
      type: "item",
      url: CONVERSATIONS,
      icon: icons.IconMessage,
      breadcrumbs: false,
    },
  ],
};

export default conversation;
