import { Navigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { arrayElementsExistInArray } from 'utils/helpers';
import { DASHBOARD } from 'constants/routes';

const HasRoles = ({ redirectPath = DASHBOARD, requiredRoles = [], children }) => {
    const {
        user: { roles }
    } = useUser();
    if (requiredRoles && requiredRoles.length && !arrayElementsExistInArray(roles, requiredRoles)) {
        return redirectPath ? <Navigate to={redirectPath} replace /> : null;
    }
    return children;
};

export default HasRoles;
