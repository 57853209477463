import React, { useState } from "react";
import { useConversationProvider } from "context/conversation/provider";
import { io } from "socket.io-client";
import { FormattedMessage, useIntl } from "react-intl";
import Peer from "simple-peer";
import useHttp from "./useHttp";
import useUser from "./useUser";
import { useSnackbar } from "notistack";
import usePermissions from "./usePermissions";
import { getCookie } from "utils/cookie";
import { TOKEN_KEY } from "constants";
import {
  ADD_MESSAGE,
  CLOSE_ADD_LEADER_DIALOG,
  CLOSE_CALL_ALERT_DIALOG,
  CLOSE_CALL_NOTIFICATION_DIALOG,
  CLOSE_CREATE_ROOM_DIALOG,
  CLOSE_END_CONVERSATION_DIALOG,
  CLOSE_FILES_ARCHIVE_DIALOG,
  CLOSE_RESTART_CONVERSATION_DIALOG,
  CLOSE_STREAM_DIALOG,
  DESTROY_PEER,
  END_STREAM,
  OPEN_ADD_LEADER_DIALOG,
  OPEN_CALL_ALERT_DIALOG,
  OPEN_CALL_NOTIFICATION_DIALOG,
  OPEN_CREATE_ROOM_DIALOG,
  OPEN_END_CONVERSATION_DIALOG,
  OPEN_FILES_ARCHIVE_DIALOG,
  OPEN_RESTART_CONVERSATION_DIALOG,
  OPEN_STREAM_DIALOG,
  SET_CALL,
  SET_CALL_ACCEPTED,
  SET_CALL_ENDED,
  SET_PEER,
  SET_PEERS,
  SET_SOCKET,
  SET_STREAM,
  SET_STREAM_TYPE,
  STORE_MESSAGES,
  STORE_ROOM,
  STORE_ROOMS,
  UNSET_SOCKET,
  UPDATE_ROOM,
  UPDATE_ROOM_STATUS,
} from "constants/actions";
import { CONVERSATION } from "constants/routes";
import { GET_USERS_BY_UUID, METERED_TURN_SERVERS, ROOM } from "constants/api";
import {
  CALL_ENDED,
  CALL_RECEIVING_RETURNINED_SIGNAL,
  CALL_REQUEST,
  CALL_REQUEST_JOIN,
  CALL_REQUEST_JOINED,
  CALL_REQUEST_REJECTED,
  CALL_RETURNING_SIGNAL,
  CALL_SENDING_SIGNAL,
  CALL_USERS,
  GET_ROOM_MESSAGES_EVENT,
  MESSAGE_STORE_EVENT,
} from "constants/events";

const iceServers = [
  {
    urls: "stun:stun.relay.metered.ca:80",
  },
  {
    urls: "turn:a.relay.metered.ca:80",
    username: "f5fe8550082e79376a1340fb",
    credential: "uBWw7LdeLkikpYEc",
  },
  {
    urls: "turn:a.relay.metered.ca:80?transport=tcp",
    username: "f5fe8550082e79376a1340fb",
    credential: "uBWw7LdeLkikpYEc",
  },
  {
    urls: "turn:a.relay.metered.ca:443",
    username: "f5fe8550082e79376a1340fb",
    credential: "uBWw7LdeLkikpYEc",
  },
  {
    urls: "turn:a.relay.metered.ca:443?transport=tcp",
    username: "f5fe8550082e79376a1340fb",
    credential: "uBWw7LdeLkikpYEc",
  },
];

// const iceServers = [
//   {
//     urls: "stun:stun.call.afzalolatebae.com"
//   },
//   {
//     urls: "turn:turn.call.afzalolatebae.com",
//     username: "afzal",
//     credential: "P@ssw0rd"
//   }
// ];

const useSocket = () => {
  const [state, dispatch] = useConversationProvider();
  const { _post } = useHttp();
  const intl = useIntl();
  const { socket } = state;
  const { user, isDoctor, isLegal } = useUser();
  const authUser = user;
  const { enqueueSnackbar } = useSnackbar();
  const { requestMediaPermission } = usePermissions();
  const { _get } = useHttp();
  const [pending, setPending] = useState(false);
  const [progress, setProgress] = useState(0);
  const conversationIsEnded = state.room.status === -1;

  const connect = (socketApi = process.env.REACT_APP_SOCKET_IO_API) => {
    const socketConnection = io(socketApi, {
      auth: {
        token: getCookie(TOKEN_KEY),
      },
      secure: true,
    });
    socketConnection.connect();
    setSocket(socketConnection);
    window.socket = socketConnection;
  };

  const disconnect = () => {
    if (socket) {
      socket.disconnect();
      unsetSocket();
    }
  };

  const createRoom = async (data) => {
    enablePending();
    return await _post(ROOM, data).finally(disablePending);
  };

  const getRoomMessages = (roomId, page = 0, perPage = 15) =>
    socket.emit(GET_ROOM_MESSAGES_EVENT, {
      id: roomId,
      page,
      perPage,
    });

  const call = async () => {
    enablePending();
    handleCloseCallAlertDialog();
    enqueueSnackbar(
      <FormattedMessage
        id={
          state.room.type === "private"
            ? "conversation.call.pending"
            : "conversation.call.group.pending"
        }
      />,
      {
        variant: "info",
      }
    );
    const peer = createPeerConnection();
    setPeer(peer);
    peer.on("signal", (signal) => {
      socket.emit(CALL_REQUEST, {
        user: authUser,
        signal,
        type: state.streamType,
        room_id: state.room.id,
        isPrivate: state.room.type === "private",
      });
    });
    peer.on("error", (err) => {
      console.error(err);
      enqueueSnackbar(
        <FormattedMessage
          id="conversation.call.failed"
          values={{ code: 101 }}
        />,
        { variant: "error" }
      );
    });
    peer.on("close", () => {
      socket.off(CALL_REQUEST_JOINED);
      socket.off(CALL_RETURNING_SIGNAL);
      socket.off(CALL_RECEIVING_RETURNINED_SIGNAL);
    });

    socket.on(CALL_REQUEST_JOINED, async ({ signal, user, room_id }) => {
      const userPeer = createPeerConnection({
        initiator: false,
        stream: state.stream,
      });

      userPeer.on("signal", (signal) => {
        socket.emit(CALL_RETURNING_SIGNAL, {
          signal,
          user: authUser,
          room_id,
        });
      });

      userPeer.on("error", (err) => {
        console.error(err);
        enqueueSnackbar(
          <FormattedMessage
            id="conversation.call.failed"
            values={{ code: 102 }}
          />,
          { variant: "error" }
        );
      });

      userPeer.signal(signal);

      setCall({ room_id });

      handleOpenStreamDialog();

      setPeers([
        ...state.peers,
        {
          peerId: user.uuid,
          peer: userPeer,
          user,
        },
      ]);
      const contactUser = findSubscriber(user.uuid);
      enqueueSnackbar(
        `${
          contactUser.full_name ?? contactUser.user_name ?? contactUser.mobile
        } ${intl.formatMessage({
          id: "conversation.group.user.joined",
        })}`,
        { variant: "info" }
      );
    });
  };

  const rejectCall = () => {
    socket.emit(CALL_REQUEST_REJECTED, { room_id: state.call.room_id, user });
    endStream();
  };

  const joinCall = () => {
    enablePending();
    handleCloseCallNotificationDialog();
    enqueueSnackbar(<FormattedMessage id="conversation.call.calling" />, {
      variant: "info",
    });
    const roomId = state.call.room_id;
    socket.emit(CALL_REQUEST_JOIN, {
      room_id: roomId,
      user,
    });

    socket.on(CALL_USERS, (users) => {
      const peers = [];
      users.forEach((user) => {
        const peer = createPeerConnection({ stream: state.stream });
        peer.on("signal", (signal) => {
          socket.emit(CALL_SENDING_SIGNAL, {
            room_id: roomId,
            user: authUser,
            signal,
          });
        });
        peer.on("close", () => {
          socket.off(CALL_USERS);
          socket.off(CALL_REQUEST_JOINED);
        });
        peer.on("error", (err) => {
          console.error(err);
          enqueueSnackbar(
            <FormattedMessage
              id="conversation.call.failed"
              values={{ code: 103 }}
            />,
            { variant: "error" }
          );
        });
        peers.push({ peerId: user.uuid, peer, user });
        if (peers.length === users.length) setPeers(peers);
      });
    });
  };

  const leaveCall = () => {
    socket.emit(CALL_ENDED, { room_id: state.call.room_id, user: authUser });
    endStream();
    handleCloseStreamDialog();
  };

  const handleDestroyPeer = (peerId) =>
    dispatch({ type: DESTROY_PEER, payload: peerId });

  const handleDestroyAuthUserPeer = () =>
    "destroy" in state.peer && state.peer.destroy();

  const createPeerConnection = (options = {}) =>
    new Peer({
      initiator: true,
      trickle: false,
      stream: state.stream,
      config: {
        iceServers,
      },
      ...options,
    });

  const getTurnServer = async () => {
    enablePending();
    return await _get(
      `${METERED_TURN_SERVERS}?apiKey=${process.env.REACT_APP_METERED_API_KEY}`
    );
  };

  const setPeers = (peers = []) =>
    dispatch({ type: SET_PEERS, payload: peers });

  const setPeer = (peer = {}) => dispatch({ type: SET_PEER, payload: peer });

  const setStream = (stream) => dispatch({ type: SET_STREAM, payload: stream });

  const endStream = (state = {}) =>
    dispatch({ type: END_STREAM, payload: state });

  const setCall = (call = {}) =>
    dispatch({
      type: SET_CALL,
      payload: call,
    });

  const setStreamType = (type) =>
    dispatch({ type: SET_STREAM_TYPE, payload: type });

  const setCallAccepted = () => dispatch({ type: SET_CALL_ACCEPTED });

  const setCallEnded = (callEnded = false) =>
    dispatch({ type: SET_CALL_ENDED, payload: callEnded });

  const storeMessages = (messages = {}) =>
    dispatch({ type: STORE_MESSAGES, payload: messages });

  const handleOpenCallNotificationDialog = () =>
    dispatch({ type: OPEN_CALL_NOTIFICATION_DIALOG });

  const handleCloseCallNotificationDialog = () =>
    dispatch({ type: CLOSE_CALL_NOTIFICATION_DIALOG });

  const handleOpenStreamDialog = () => dispatch({ type: OPEN_STREAM_DIALOG });

  const handleCloseStreamDialog = () => dispatch({ type: CLOSE_STREAM_DIALOG });

  const handleOpenCallAlertDialog = () =>
    dispatch({ type: OPEN_CALL_ALERT_DIALOG });

  const handleCloseCallAlertDialog = () =>
    dispatch({ type: CLOSE_CALL_ALERT_DIALOG });

  const sendMessage = (data) => socket.emit(MESSAGE_STORE_EVENT, data);

  const storeRooms = (rooms = []) =>
    dispatch({ type: STORE_ROOMS, payload: rooms });

  const storeRoom = (room = {}) =>
    dispatch({ type: STORE_ROOM, payload: room });

  const updateRoom = (room) => dispatch({ type: UPDATE_ROOM, payload: room });

  const updateRoomStatus = (status) =>
    dispatch({ type: UPDATE_ROOM_STATUS, payload: status });

  const getRoomSubscribers = async (ids) => {
    enablePending();
    return await _post(GET_USERS_BY_UUID, { uuid: ids }).finally(
      disablePending
    );
  };

  const addMessage = async (message = {}) =>
    dispatch({ type: ADD_MESSAGE, payload: message });

  const setSocket = (socket) => dispatch({ type: SET_SOCKET, payload: socket });
  const unsetSocket = () => dispatch({ type: UNSET_SOCKET });

  const roomsObjectBuilder = (rooms = [], onlyMessageText = true) => {
    if (rooms.length) {
      return rooms.map(({ room: { id, type, group, messages } }) => {
        const result = {
          id,
          href: CONVERSATION.replace(":id", `${id}`),
        };
        if (type === "group") {
          const title = group?.title.trim().split("-");
          result.title =
            isDoctor || isLegal ? title[1] : `${title[0]} - ${title[1]}`;
        }
        const lastMessage = messages[messages.length - 1];
        const messageBody = lastMessage?.body;
        let message = messageBody
          ? messageBody
          : lastMessage?.attachments?.length
          ? intl
              .formatMessage({ id: "conversation.media" })
              .replace("{count}", lastMessage.attachments.length)
          : intl.formatMessage({ id: "conversation.start" });
        if (onlyMessageText) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = message;
          let messageText = tempDiv.textContent;
          if (messageText.length > 50)
            messageText = `${messageText.substring(0, 50)}...`;
          result.body = messageText;
        } else result.body = message;
        return result;
      });
    }
    return [];
  };

  const handleScrollBodyToBottom = (ref) => {
    const bodyDiv = ref?.current;
    bodyDiv.scrollTo({
      left: 0,
      top: bodyDiv.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleOpenCreateRoomDialog = () =>
    dispatch({ type: OPEN_CREATE_ROOM_DIALOG });
  const handleCloseCreateRoomDialog = () =>
    dispatch({ type: CLOSE_CREATE_ROOM_DIALOG });

  const handleOpenEndConversationDialog = () =>
    dispatch({ type: OPEN_END_CONVERSATION_DIALOG });
  const handleCloseEndConversationDialog = () =>
    dispatch({ type: CLOSE_END_CONVERSATION_DIALOG });

  const handleOpenRestartConversationDialog = () =>
    dispatch({ type: OPEN_RESTART_CONVERSATION_DIALOG });
  const handleCloseRestartConversationDialog = () =>
    dispatch({ type: CLOSE_RESTART_CONVERSATION_DIALOG });

  const handleOpenAddLeaderDialog = () =>
    dispatch({ type: OPEN_ADD_LEADER_DIALOG });
  const handleCloseAddLeaderDialog = () =>
    dispatch({ type: CLOSE_ADD_LEADER_DIALOG });

  const handleOpenFilesArchiveDialog = () =>
    dispatch({ type: OPEN_FILES_ARCHIVE_DIALOG });
  const handleCloseFilesArchiveDialog = () =>
    dispatch({ type: CLOSE_FILES_ARCHIVE_DIALOG });

  const handleGetUserMediaPermission = async () => {
    await requestMediaPermission()
      .then((stream) => setStream(stream))
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(<FormattedMessage id="global.callNotSupported" />, {
          variant: "error",
        });
      });
  };

  const findSubscriber = (id, key = "uuid") =>
    state?.room?.subscribers?.find((user) => user[key] === id);

  const enablePending = () => setPending(true);
  const disablePending = () => setPending(false);
  const resetProgress = () => setProgress(0);

  return {
    state,
    socket,
    pending,
    progress,
    conversationIsEnded,
    setSocket,
    connect,
    disconnect,
    getRoomMessages,
    storeMessages,
    storeRooms,
    storeRoom,
    updateRoom,
    updateRoomStatus,
    addMessage,
    roomsObjectBuilder,
    handleScrollBodyToBottom,
    getRoomSubscribers,
    createRoom,
    handleOpenCreateRoomDialog,
    handleCloseCreateRoomDialog,
    handleOpenEndConversationDialog,
    handleCloseEndConversationDialog,
    handleOpenRestartConversationDialog,
    handleCloseRestartConversationDialog,
    handleOpenAddLeaderDialog,
    handleCloseAddLeaderDialog,
    handleOpenFilesArchiveDialog,
    handleCloseFilesArchiveDialog,
    sendMessage,
    enablePending,
    disablePending,
    call,
    joinCall,
    rejectCall,
    endStream,
    handleOpenCallAlertDialog,
    handleCloseCallAlertDialog,
    handleOpenCallNotificationDialog,
    handleCloseCallNotificationDialog,
    handleOpenStreamDialog,
    handleCloseStreamDialog,
    setStreamType,
    setCall,
    handleDestroyAuthUserPeer,
    endStream,
    leaveCall,
    setStream,
    handleDestroyPeer,
    handleGetUserMediaPermission,
    findSubscriber,
  };
};

export default useSocket;
