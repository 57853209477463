// assets
import { FormattedMessage } from "react-intl";
import { ABOUT, ARTICLES, HIGHLIGHTS } from "constants/routes";
import { IconArticle, IconCategory, IconPencil } from "@tabler/icons";
import { SUPER_ADMIN_ROLE, DOCTOR_ROLE, ADVISER_ROLE, LEGAL_ROLE } from "constants";

// constant
const icons = { IconArticle, IconCategory, IconPencil };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const articles = {
  id: "profile",
  title: <FormattedMessage id="sidebar.profile.title" />,
  type: "group",
  roles: [SUPER_ADMIN_ROLE, DOCTOR_ROLE, ADVISER_ROLE, LEGAL_ROLE],
  children: [
    {
      id: "articles",
      title: <FormattedMessage id="sidebar.articles.title" />,
      type: "item",
      url: ARTICLES,
      icon: icons.IconArticle,
      breadcrumbs: false,
    },
    {
      id: "highlights",
      title: <FormattedMessage id="sidebar.highlights.title" />,
      type: "item",
      url: HIGHLIGHTS,
      icon: icons.IconCategory,
      breadcrumbs: false,
    },
    {
      id: "about",
      title: <FormattedMessage id="sidebar.about.title" />,
      type: "item",
      url: ABOUT,
      icon: icons.IconPencil,
      breadcrumbs: false,
    },
  ],
};

export default articles;
