export const GET_ROOMS_EVENT = "get:rooms";
export const ROOMS_READY_EVENT = "rooms:ready";
export const GET_ROOM_EVENT = "get:room";
export const GET_ROOM_MESSAGES_EVENT = "get:room:messages";
export const ROOM_READY_EVENT = "room:ready";
export const MESSAGE_SENT_EVENT = "message:sent";
export const MESSAGE_SENT_OTHER_EVENT = "message:sent:other";
export const MESSAGE_STORE_EVENT = "message:store";
export const END_TEXT_CONVERSATION_EVENT = "conversation:text:end";
export const RESTART_TEXT_CONVERSATION_EVENT = "conversation:text:restart";
export const ADD_SUBSCRIBER_EVENT = "conversation:add:subscriber";
export const ERROR_EVENT = "error";

export const CALL_REQUEST = "call:request";
export const CALL_REQUEST_REJECTED = "call:request:rejected";
export const CALL_REQUEST_JOIN = "call:request:join";
export const CALL_REQUEST_JOINED = "call:request:joined";
export const CALL_USERS = "call:users";
export const CALL_SENDING_SIGNAL = "call:sendingSignal";
export const CALL_RETURNING_SIGNAL = "call:returningSignal";
export const CALL_RECEIVING_RETURNINED_SIGNAL = "call:receivingReturnedSignal";
export const CALL_ENDED = "call:ended";