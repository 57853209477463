// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_USER = "set_user";
export const CLEAR_USER = "clear_user";
export const CHANGE_LOCALE = "change_locale";

// Article actions

export const STORE_ARTICLES = "store_articles";
export const STORE_ARTICLE = "store_article";
export const DELETE_ARTICLE = "delete_article";
export const CLEAR_ARTICLES = "clear_articles";
export const OPEN_DESTROY_CONFIRM_DIALOG = "open_destroy_confirm_dialog";
export const CLOSE_DESTROY_CONFIRM_DIALOG = "close_destroy_confirm_dialog";
export const SET_ARTICLE_ACTION = "set_article_action";
export const SET_ARTICLE_SELECTED_ROW = "set_selected_row";
export const OPEN_REPLY_DIALOG = "open_reply_dialog";
export const CLOSE_REPLY_DIALOG = "close_reply_dialog";

// Highlight actions
export const STORE_HIGHLIGHTS = "store_highlights";
export const STORE_HIGHLIGHT = "store_highlight";
export const ADD_HIGHLIGHT = "add_new_highlight";
export const UPDATE_HIGHLIGHT = "update_highlight";
export const REMOVE_HIGHLIGHT = "remove_highlight";
export const CLEAR_HIGHLIGHTS = "clear_highlights";
export const OPEN_CREATE_HIGHLiGHT_DIALOG = "open_create_highlight_dialog";
export const CLOSE_CREATE_HIGHLiGHT_DIALOG = "close_create_highlight_dialog";
export const OPEN_EDIT_HIGHLiGHT_DIALOG = "open_edit_highlight_dialog";
export const CLOSE_EDIT_HIGHLiGHT_DIALOG = "close_edit_highlight_dialog";
export const SET_HIGHLIGHT_ACTION = "set_highlight_action";
export const SET_HIGHLIGHT_SELECTED_ROW = "set_selected_row";

// User actions

export const SET_AVATAR = "set_avatar";
export const DELETE_AVATAR = "delete_avatar";
export const UPDATE_USER = "update_user";

// Socket actions

export const SET_SOCKET = "set_socket";
export const UNSET_SOCKET = "unset_socket";

// Conversation actions

export const STORE_ROOMS = "store_rooms";
export const STORE_ROOM = "store_room";
export const UPDATE_ROOM = "update_room";
export const UPDATE_ROOM_STATUS = "update_room_status";
export const STORE_MESSAGES = "store_messages";
export const ADD_MESSAGE = "add_message";
export const OPEN_CREATE_ROOM_DIALOG = "open_create_room_dialog";
export const CLOSE_CREATE_ROOM_DIALOG = "close_create_room_dialog";
export const OPEN_END_CONVERSATION_DIALOG = "open_end_conversation_dialog";
export const CLOSE_END_CONVERSATION_DIALOG = "close_end_conversation_dialog";
export const OPEN_RESTART_CONVERSATION_DIALOG =
  "open_restart_conversation_dialog";
export const CLOSE_RESTART_CONVERSATION_DIALOG =
  "close_restart_conversation_dialog";
export const OPEN_ADD_LEADER_DIALOG = "open_add_leader_dialog";
export const CLOSE_ADD_LEADER_DIALOG = "close_add_leader_dialog";
export const OPEN_FILES_ARCHIVE_DIALOG = "open_files_archive_dialog";
export const CLOSE_FILES_ARCHIVE_DIALOG = "close_files_archive_dialog";
export const SET_PEER = "set_peer";
export const SET_PEERS = "set_peers";
export const DESTROY_PEER = "destroy_peer";
export const SET_STREAM = "set_stream";
export const SET_CALL = "set_call";
export const SET_STREAM_TYPE = "set_stream_type";
export const SET_CALL_ACCEPTED = "set_call_accepted";
export const SET_CALL_ENDED = "set_call_ended";
export const OPEN_CALL_NOTIFICATION_DIALOG = "open_call_notification_dialog";
export const CLOSE_CALL_NOTIFICATION_DIALOG = "close_call_notification_dialog";
export const OPEN_STREAM_DIALOG = "open_stream_dialog";
export const CLOSE_STREAM_DIALOG = "close_stream_dialog";
export const OPEN_CALL_ALERT_DIALOG = "open_call_alert_dialog";
export const CLOSE_CALL_ALERT_DIALOG = "close_call_alert_dialog";
export const END_STREAM = "end_stream";
// Category actions
export const STORE_CATEGORIES = "store_categories";
export const ADD_CATEGORY = "add_category";
export const UPDATE_CATEGORY = "update_category";
export const DELETE_CATEGORY = "delete_category";
export const SET_CATEGORIES_ACTION = "set_categories_action";
export const SET_SELECTED_CATEGORY = "set_selected_category";
export const OPEN_CREATE_CATEGORY_DIALOG = "open_create_category_dialog";
export const CLOSE_CREATE_CATEGORY_DIALOG = "close_create_category_dialog";
export const OPEN_EDIT_CATEGORY_DIALOG = "open_edit_category_dialog";
export const CLOSE_EDIT_CATEGORY_DIALOG = "close_edit_category_dialog";
export const OPEN_DESTROY_CATEGORY_DIALOG = "open_destroy_category_dialog";
export const CLOSE_DESTROY_CATEGORY_DIALOG = "close_destroy_category_dialog";

// Question-Answer actions

export const STORE_QUESTIONS = "store_questions";
export const OPEN_QUESTION_DESTROY_DIALOG = "open_destroy_dialog";
export const CLOSE_QUESTION_DESTROY_DIALOG = "close_destroy_dialog";
export const SET_QUESTION_SELECTED_ROW = "set_question_selected_row";
export const DELETE_QUESTION = "delete_question";
export const SET_QUESTION_ACTION = "set_question_action";

// Legal actions

export const STORE_LEGAL_MEMBERS = "store_legal_members";
export const STORE_LEGAL_MEMBER = "store_legal_member";
export const SET_LEGAL_ACTION = "store_legal_action";
export const OPEN_LEGAL_MEMBER_DESTORY_ALERT_DIALOG =
  "open_legal_member_destroy_alert_dialog";
export const CLOSE_LEGAL_MEMBER_DESTORY_ALERT_DIALOG =
  "close_legal_member_destroy_alert_dialog";
export const OPEN_ADD_LEGAL_MEMBER_DIALOG = "open_add_legal_member_dialog";
export const CLOSE_ADD_LEGAL_MEMBER_DIALOG = "close_add_legal_member_dialog";
export const DELETE_LEGAL_MEMBER = "delete_legal_member";
export const SET_LEGAL_SELECTED_MEMBER = "set_legal_selected_member";

// Slideshow actions

export const STORE_SLIDES = "store_slides";
export const STORE_SLIDE = "store_slide";
export const UPDATE_SLIDE = "update_slide";
export const SET_SLIDE_SHOW_ACTION = "set_slide_show_action";
export const SET_SELECTED_SLIDE = "set_selected_slide";
export const DELETE_SLIDE = "delete_slide";
export const OPEN_CREATE_SLIDE_DIALOG = "open_create_slide_dialog";
export const CLOSE_CREATE_SLIDE_DIALOG = "close_create_slide_dialog";
export const OPEN_EDIT_SLIDE_DIALOG = "open_edit_slide_dialog";
export const CLOSE_EDIT_SLIDE_DIALOG = "close_edit_slide_dialog";
export const OPEN_DESTROY_SLIDE_DIALOG = "open_destroy_slide_dialog";
export const CLOSE_DESTROY_SLIDE_DIALOG = "close_destroy_slide_dialog";

// Services actions

export const STORE_SERVICES = "store_services";
export const ADD_SERVICE = "add_service";
export const UPDATE_SERVICE = "update_service";
export const DELETE_SERVICE = "delete_service";
export const SET_SERVICES_ACTION = "set_services_action";
export const SET_SELECTED_SERVICE = "set_selected_service";
export const OPEN_CREATE_SERVICE_DIALOG = "open_create_service_dialog";
export const CLOSE_CREATE_SERVICE_DIALOG = "close_create_service_dialog";
export const OPEN_EDIT_SERVICE_DIALOG = "open_edit_service_dialog";
export const CLOSE_EDIT_SERVICE_DIALOG = "close_edit_service_dialog";
export const OPEN_DESTROY_SERVICE_DIALOG = "open_destroy_service_dialog";
export const CLOSE_DESTROY_SERVICE_DIALOG = "close_destroy_service_dialog";
