// assets
import { FormattedMessage } from "react-intl";
import { SERVICES } from "constants/routes";
import { IconTools } from "@tabler/icons";
import { SUPER_ADMIN_ROLE, DOCTOR_ROLE, LEGAL_ROLE } from "constants";

// constant
const icons = { IconTools };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const services = {
  id: "services",
  title: <FormattedMessage id="sidebar.services.title" />,
  type: "group",
  roles: [SUPER_ADMIN_ROLE, DOCTOR_ROLE, LEGAL_ROLE],
  children: [
    {
      id: "services-index",
      title: <FormattedMessage id="sidebar.services.manage.title" />,
      type: "item",
      url: SERVICES,
      icon: icons.IconTools,
      breadcrumbs: false,
    },
  ],
};

export default services;
