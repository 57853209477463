import { useState, useRef, useEffect } from "react";
import useApp from "hooks/useApp";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  useMediaQuery,
} from "@mui/material";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

// assets
import { setCookie } from "utils/cookie";
import { LOCALE_KEY } from "constants";
import { CROSS_DOMAIN_COOKIE } from "constants";
import { CHANGE_LOCALE } from "constants/actions";
import { IconLanguage } from "@tabler/icons";

const flagsStyles = {
  width: 32,
  height: 32,
  objectFit: "cover",
  marginLeft: "8px",
  borderRadius: "4px",
};

// ==============================|| NOTIFICATION ||============================== //

const LanguageSwitcher = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const {
    state: { locale },
    dispatch,
  } = useApp();

  const [open, setOpen] = useState(false);
  const locales = [
    {
      label: "Arabic",
      value: "ar",
      icon: (
        <img
          src="/images/flags/Iraq.svg"
          style={flagsStyles}
          alt="Arabic Language"
        />
      ),
    },
    {
      label: "Persian",
      value: "fa",
      icon: (
        <img
          src="/images/flags/Iran.svg"
          style={flagsStyles}
          alt="Persian Language"
        />
      ),
    },
  ];
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSwitchLanguage = (locale) => {
    if (locale) {
      setCookie(LOCALE_KEY, locale, {
        domain: CROSS_DOMAIN_COOKIE,
        expires: 365,
      });
      dispatch({ type: CHANGE_LOCALE, payload: locale });
      return true;
    }
    return false;
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <IconLanguage stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <List>
                    {locales.map(({ label, value, icon }, index) => (
                      <ListItem
                        disablePadding
                        key={`language-${index}`}
                        sx={{ bgcolor: locale === value ? "primary.main" : "" }}
                      >
                        <ListItemButton
                          onClick={() => handleSwitchLanguage(value)}
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText primary={label} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default LanguageSwitcher;
