const HighlightState = {
  highlights: [],
  highlight: {},
  createDialogIsOpen: false,
  editDialogIsOpen: false,
  confirmDestroyDialogIsOpen: false,
  action: null,
  selectedRow: null,
};

export default HighlightState;
