import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CircularProgress from "./CircularProgress";
import Tooltip from "@mui/material/Tooltip";

const CircularProgressWithLabel = ({ second, value, title }) => {
  return (
    <Box position="fixed" right="5%" bottom="4%">
      {title ? (
        <Tooltip title={title}>
          <Paper
            elevation={6}
            sx={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
          >
            <CircularProgress
              progressProps={{ variant: "determinate", value: value }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >{`${Math.round(second)}`}</Typography>
            </Box>
          </Paper>
        </Tooltip>
      ) : (
        <Paper
          elevation={6}
          sx={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        >
          <CircularProgress
            progressProps={{ variant: "determinate", value: value }}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{`${Math.round(second)}`}</Typography>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

const TimeProgresWithLabel = ({ onEnd, duration, title = null }) => {
  const [progress, setProgress] = useState(100);
  const [second, setSecond] = useState(duration);
  const incrementEverySecond = 100 / Math.round(duration);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond((currentSecond) => currentSecond - 1);
      setProgress((prevProgress) =>
        prevProgress <= incrementEverySecond
          ? onEnd()
          : prevProgress - incrementEverySecond
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (second <= 0) onEnd();
  }, [second]);

  return (
    <CircularProgressWithLabel value={progress} second={second} title={title} />
  );
};

export default TimeProgresWithLabel;
