import React, { useContext, useReducer } from "react";
import QuestionAnswerReducer from "context/questionAnswer/reducer";
import QuestionAnswerState from "context/questionAnswer/state";

const QuestionAnswerContext = React.createContext();

const QuestionAnswerProvider = ({ children }) => {
  const contextValue = useReducer(QuestionAnswerReducer, QuestionAnswerState);
  return (
    <QuestionAnswerContext.Provider value={contextValue}>
      {children}
    </QuestionAnswerContext.Provider>
  );
};
export default QuestionAnswerProvider;

export const useQuestionAnswerProvider = () => {
  const contextValue = useContext(QuestionAnswerContext);
  return contextValue;
};
