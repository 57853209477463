import { useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useUserProvider } from "context/auth/provider";
import useHttp from "hooks/useHttp";
import useLanguages from "./useLanguages";
import { deleteCookie, setCookie } from "utils/cookie";
import {
  CHECK_EXIST,
  FORGOT_PASSWORd,
  LOGIN,
  LOGOUT,
  REGISTER,
  USER_DETAILS,
} from "constants/api";
import { TOKEN_KEY, CROSS_DOMAIN_COOKIE } from "constants";
import { DASHBOARD, EDIT_PROFILE, SIGN_IN } from "constants/routes";
import { CLEAR_USER, SET_USER } from "constants/actions";

const useAuth = () => {
  const [{ user, isLoggedIn }, dispatch] = useUserProvider();
  const navigate = useNavigate();
  const { _get, _post } = useHttp();
  const { formatLanguagesForAutocomplete } = useLanguages();
  const [searchParams] = useSearchParams();
  const nextPage = searchParams.get("next");
  const [pending, setPending] = useState(false);

  const login = async (credential = {}) =>
    await _post(LOGIN, credential).then(({ data: { data: response } }) => {
      const {
        token,
        token_expires_at,
        token_expiry_duration,
        token_type,
        roles,
        ...updatedUser
      } = response;
      storeToken(response.token, {
        expires: new Date(token_expires_at),
        domain: CROSS_DOMAIN_COOKIE,
      });
      updatedUser.roles = roles;
      updatedUser.permission = response.permission;
      storeUser(updatedUser);
      const redirectUrl = getRedirectUrl(updatedUser);
      navigate(nextPage ?? redirectUrl);
    });

  const register = async (formData, registerRoute = REGISTER) => {
    delete formData.agreeTerms;
    return await _post(registerRoute, formData).then(({ data: { data } }) => {
      const { token, token_expires_at, ...user } = data;
      storeToken(token, {
        expires: new Date(token_expires_at),
        domain: CROSS_DOMAIN_COOKIE,
      });
      storeUser(user);
      const redirectUrl = getRedirectUrl(user);
      navigate(redirectUrl);
    });
  };

  const storeToken = (token, options = {}) => {
    return setCookie(TOKEN_KEY, token, options);
  };

  const identity = async () => {
    return await _get(USER_DETAILS)
      .then(({ data: { data: user } }) => {
        const { roles, user: userDetails } = user;
        userDetails.roles = roles;
        userDetails.languages = formatLanguagesForAutocomplete(
          userDetails.languages
        );
        storeUser(userDetails);
      })
      .catch(logout);
  };

  const logout = async () => {
    enablePending();
    await _get(LOGOUT).finally(() => {
      clearUser();
      deleteCookie(TOKEN_KEY, { domain: CROSS_DOMAIN_COOKIE });
      window.location.replace(SIGN_IN);
    });
  };

  const forgotPassword = async (formData) => {
    return await _post(FORGOT_PASSWORd, formData);
  };

  const checkFieldIsUnique = async (data) => {
    enablePending(true);
    return await _post(CHECK_EXIST, data).finally(disablePending);
  };

  const getRedirectUrl = (user) => {
    return !user?.email || !user?.user_name ? EDIT_PROFILE : DASHBOARD;
  };

  const storeUser = (user) => dispatch({ type: SET_USER, payload: user });
  const clearUser = () => dispatch({ type: CLEAR_USER });

  const enablePending = () => setPending(true);
  const disablePending = () => setPending(false);

  return {
    user,
    isLoggedIn,
    pending,
    login,
    register,
    identity,
    logout,
    forgotPassword,
    checkFieldIsUnique,
    enablePending,
    disablePending,
  };
};

export default useAuth;
