const SlideShowState = {
  slides: [],
  selectedRow: null,
  action: null,
  destroySlideDialogIsOpen: false,
  createSlideDialogIsOpen: false,
  editSlideDialogIsOpen: false,
};

export default SlideShowState;
