import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import AuthRoute from "middlewares/authRoute";
import Loadable from "ui-component/Loadable";
import HasRoles from "middlewares/hasRoles";
const Doctors = Loadable(lazy(() => import("views/doctors")));
const CreateLegalMember = Loadable(
  lazy(() => import("views/doctors/forms/create"))
);
const EditLegalMember = Loadable(
  lazy(() => import("views/doctors/forms/edit"))
);
const Slideshow = Loadable(lazy(() => import("views/slideShow")));
import {
  CREATE_LEGAL_DOCTOR,
  DASHBOARD,
  EDIT_LEGAL_DOCTOR,
  LEGAL_DOCTORS,
  SLIDE_SHOW,
} from "constants/routes";
import { LEGAL_ROLE } from "constants";

const LegalRoutes = {
  path: DASHBOARD,
  element: (
    <AuthRoute>
      <MainLayout />
    </AuthRoute>
  ),
  children: [
    {
      path: LEGAL_DOCTORS,
      element: (
        <HasRoles requiredRoles={[LEGAL_ROLE]}>
          <Doctors />
        </HasRoles>
      ),
    },
    {
      path: CREATE_LEGAL_DOCTOR,
      element: (
        <HasRoles requiredRoles={[LEGAL_ROLE]}>
          <CreateLegalMember />
        </HasRoles>
      ),
    },
    {
      path: EDIT_LEGAL_DOCTOR,
      element: (
        <HasRoles requiredRoles={[LEGAL_ROLE]}>
          <EditLegalMember />
        </HasRoles>
      ),
    },
    {
      path: SLIDE_SHOW,
      element: (
        <HasRoles requiredRoles={[LEGAL_ROLE]}>
          <Slideshow />
        </HasRoles>
      ),
    },
  ],
};

export default LegalRoutes;
