import MuiCircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularProgress({ boxProps = {}, progressProps = {} }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }} {...boxProps}>
            <MuiCircularProgress {...progressProps} />
        </Box>
    );
}
