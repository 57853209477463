import { useAppProvider } from 'context/app/provider';

const useApp = () => {
    const [state, dispatch] = useAppProvider();

    return {
        state,
        dispatch
    };
};

export default useApp;
