const ArticleState = {
  articles: [],
  article: {},
  confirmDestroyDialogIsOpen: false,
  replyCommentDialogIsOpen: false,
  action: null,
  selectedRow: null,
};

export default ArticleState;
