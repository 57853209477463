import { FRONTEND_HOME } from 'constants/routes';

const Logo = ({ alt = 'Logo', LinkProps = {}, ...props }) => {
    return (
        <a href={FRONTEND_HOME} target="_blank" {...LinkProps}>
            <img src="/images/logo.png" alt={alt} {...props} />
        </a>
    );
};

export default Logo;
