import {
  ADD_SERVICE,
  CLOSE_CREATE_SERVICE_DIALOG,
  CLOSE_DESTROY_SERVICE_DIALOG,
  CLOSE_EDIT_SERVICE_DIALOG,
  DELETE_SERVICE,
  OPEN_CREATE_SERVICE_DIALOG,
  OPEN_DESTROY_SERVICE_DIALOG,
  OPEN_EDIT_SERVICE_DIALOG,
  SET_SELECTED_SERVICE,
  SET_SERVICES_ACTION,
  STORE_SERVICES,
  UPDATE_SERVICE,
} from "constants/actions";

const ServicesReducer = (state, { type, payload }) => {
  switch (type) {
    case STORE_SERVICES:
      return {
        ...state,
        services: payload,
      };
    case ADD_SERVICE:
      return {
        ...state,
        services: {
          ...state.services,
          data: [payload, ...state.services.data],
        },
      };
    case UPDATE_SERVICE:
      const updatedServiceIndex = state.services.data.findIndex(
        (service) => service.id === state.selectedRow
      );
      if (updatedServiceIndex >= 0)
        state.services.data[updatedServiceIndex] = payload;
      return {
        ...state,
      };
    case DELETE_SERVICE:
      state.services.data = state.services.data.filter(
        (service) => service.id !== state.selectedRow
      );
      return {
        ...state,
      };
    case SET_SERVICES_ACTION:
      return {
        ...state,
        action: payload,
      };
    case SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedRow: payload,
      };
    case OPEN_CREATE_SERVICE_DIALOG:
      return {
        ...state,
        createServiceDialogIsOpen: true,
      };
    case CLOSE_CREATE_SERVICE_DIALOG:
      return {
        ...state,
        createServiceDialogIsOpen: false,
      };
    case OPEN_EDIT_SERVICE_DIALOG:
      return {
        ...state,
        editServiceDialogIsOpen: true,
      };
    case CLOSE_EDIT_SERVICE_DIALOG:
      return {
        ...state,
        editServiceDialogIsOpen: false,
      };
    case OPEN_DESTROY_SERVICE_DIALOG:
      return {
        ...state,
        destroyServiceDialogIsOpen: true,
      };
    case CLOSE_DESTROY_SERVICE_DIALOG:
      return {
        ...state,
        destroyServiceDialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default ServicesReducer;
