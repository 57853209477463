import React from "react";
import AppProvider from "./app/provider";
import ArticleProvider from "./article/provider";
import UserProvider from "./auth/provider";
import CategoryProvider from "./category/provider";
import ConversationProvider from "./conversation/provider";
import HighlightProvider from "./highlight/provider";
import QuestionAnswerProvider from "./questionAnswer/provider";
import LegalProvider from "./legal/provider";
import SlideShowProvider from "./slideShow/provider";
import ServicesProvider from "./services/provider";

const ContextProvider = ({ children }) => {
  return (
    <AppProvider>
      <UserProvider>
        <CategoryProvider>
          <ArticleProvider>
            <HighlightProvider>
              <ConversationProvider>
                <QuestionAnswerProvider>
                  <LegalProvider>
                    <SlideShowProvider>
                      <ServicesProvider>{children}</ServicesProvider>
                    </SlideShowProvider>
                  </LegalProvider>
                </QuestionAnswerProvider>
              </ConversationProvider>
            </HighlightProvider>
          </ArticleProvider>
        </CategoryProvider>
      </UserProvider>
    </AppProvider>
  );
};

export default ContextProvider;
