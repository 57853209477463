import config from 'config';
import { getCookie, setCookie } from 'utils/cookie';
import { LOCALES, DEFAULT_LOCALE, LOCALE_KEY, CROSS_DOMAIN_COOKIE } from 'constants';

let locale = getCookie(LOCALE_KEY);

if (locale) {
    if (!LOCALES.includes(locale)) {
        locale = DEFAULT_LOCALE;
        setCookie(LOCALE_KEY, DEFAULT_LOCALE, { domain: CROSS_DOMAIN_COOKIE });
    }
} else {
    locale = DEFAULT_LOCALE;
    setCookie(LOCALE_KEY, DEFAULT_LOCALE, { domain: CROSS_DOMAIN_COOKIE });
}

const AppState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    locale: locale
};

export default AppState;
