import React, { useContext, useReducer } from "react";
import ServicesReducer from "context/services/reducer";
import ServicesState from "context/services/state";

const ServicesContext = React.createContext();

const ServicesProvider = ({ children }) => {
  const contextValue = useReducer(ServicesReducer, ServicesState);
  return (
    <ServicesContext.Provider value={contextValue}>
      {children}
    </ServicesContext.Provider>
  );
};
export default ServicesProvider;

export const useServicesProvider = () => {
  const contextValue = useContext(ServicesContext);
  return contextValue;
};
