import useUser from 'hooks/useUser';
// material-ui
import Typography from 'components/Typography';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { arrayElementsExistInArray } from 'utils/helpers';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const {
        user: { roles }
    } = useUser();
    const navItems = menuItem.items.map((item) => {
        const requiredRoles = item.roles;
        switch (item.type) {
            case 'group':
                if (requiredRoles && requiredRoles.length) {
                    return arrayElementsExistInArray(roles, requiredRoles) && <NavGroup key={item.id} item={item} />;
                }
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
