const ServicesState = {
  services: [],
  selectedRow: null,
  action: null,
  destroyServiceDialogIsOpen: false,
  createServiceDialogIsOpen: false,
  editServiceDialogIsOpen: false,
};

export default ServicesState;
